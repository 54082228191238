import { mdiMapSearchOutline } from '@mdi/js'
import Icon from '@mdi/react'
import * as React from 'react'

export default class NotFound extends React.Component {
    render () {
        return (
            <div className="h-100 w-100 d-flex justify-content-center align-items-center flex-column text-primary">
                <h1 className="display-1">404</h1>
                <h3 className="display-3 text-center">Page non trouvée</h3>
                <span className="fa-stack fa-5x">
                    <Icon path={mdiMapSearchOutline} size={5} />
                </span>
            </div>
        )
    }
}
