import * as React from 'react'
import { observer } from 'mobx-react'
import Store from './store'
import { S3File } from './entities'
import * as cn from 'classnames'
import Moment from 'react-moment'
import { filesize } from 'filesize'
import { mdiFileImageOutline, mdiFileOutline, mdiFilePdfBox } from '@mdi/js'
import Icon from '@mdi/react'
import { getKernel } from '@code-202/kernel'

interface Props {
    file: S3File
}

interface State {}

class ListFileView extends React.Component<Props, State> {

    protected objectStore: Store

    constructor(props: Props) {
        super(props)

        this.objectStore = getKernel().container.get('objectStore') as Store
    }

    render () {
        const { file } = this.props

        let icon = mdiFileOutline
        switch (file.type) {
            case 'img':
                icon = mdiFileImageOutline
                break
            case 'pdf':
                icon = mdiFilePdfBox
                break
        }

        return (
            <>
                <tr
                    onClick={() => this.objectStore.toggleCurrentObject(file)}
                    className={cn({'table-primary': this.objectStore.currentObjects.indexOf(file) >= 0 })}
                    >
                    <td>
                        <Icon size={1} path={icon} className="me-2" />
                        { file.name }
                    </td>
                    <td>
                        <Moment fromNow withTitle titleFormat="D MMM YYYY HH:mm:ss">{ file.lastModified.toISOString() }</Moment>
                    </td>
                    <td>
                        { filesize(file.size) }
                    </td>
                </tr>
            </>
        )
    }
}

export default observer(ListFileView)