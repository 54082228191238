import * as events from 'eventemitter3'

export class Navigator extends events.EventEmitter {

    redirectTo (path: string) {
        this.emit('redirect', path)
    }

    goBack () {
        this.emit('goBack')
    }
}
