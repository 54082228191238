import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { observer } from 'mobx-react'
import Store from './store'
import {
    Button,
    Form, FormFeedback,
    InputGroup, InputGroupText, Input,
    Modal, ModalBody
} from 'reactstrap'
import { getKernel } from '@code-202/kernel'

interface Props {}

interface State {}

class ModalCreateDirectory extends React.Component<Props, State> {
    protected newDirectoryInput: React.RefObject<HTMLInputElement>

    protected objectStore: Store

    constructor(props: Props) {
        super(props)

        this.objectStore = getKernel().container.get('objectStore') as Store
        this.newDirectoryInput = React.createRef<HTMLInputElement>()
    }

    render () {

        return (
            <Modal
                isOpen={this.objectStore.directoryCreator.createModalIsOpen}
                toggle={this.toggleCreateDirectoryModal}
                centered={true}
                onOpened={this.onCreateModalOpenedHandler}
                >
                <ModalBody>
                    <Form onSubmit={this.onSubmitCreateHandler}>
                        <InputGroup className="mb-3">
                            <InputGroupText>
                                <FormattedMessage id="app.objects.add.directory.new_name" />
                            </InputGroupText>
                            <Input
                                type="text"
                                onChange={this.onChangeNewDirectoryNameHandler}
                                invalid={this.objectStore.directoryCreator.newDirectoryNameError !== false}
                                innerRef={this.newDirectoryInput}
                                />
                            { this.objectStore.directoryCreator.newDirectoryNameError !== false && this.objectStore.directoryCreator.newDirectoryNameError && (
                                <FormFeedback>
                                    <FormattedMessage id={this.objectStore.directoryCreator.newDirectoryNameError} />
                                </FormFeedback>
                            )}
                        </InputGroup>
                        <div className="d-flex justify-content-between">
                            <Button
                                color="secondary"
                                onClick={this.toggleCreateDirectoryModal}
                                >
                                <FormattedMessage id="app.objects.add.directory.cancel" />
                            </Button>
                            <Button
                                type="submit"
                                color="success"
                                >
                                <FormattedMessage id="app.objects.add.directory.confirm" />
                            </Button>
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        )
    }

    toggleCreateDirectoryModal = () => {
        this.objectStore.directoryCreator.toggleCreateDirectoryModal()
    }

    onChangeNewDirectoryNameHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.objectStore.directoryCreator.setNewDirectoryName(event.target.value)
    }

    onCreateModalOpenedHandler = () => {
        if (this.newDirectoryInput.current) {
            this.newDirectoryInput.current.focus()
        }
    }

    onSubmitCreateHandler = (event: React.FormEvent) => {
        event.preventDefault()

        this.objectStore.directoryCreator.create()
    }
}

export default observer(ModalCreateDirectory)
