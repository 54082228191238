import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { observer } from 'mobx-react'
import Store from './store'
import {
    Alert,
    Button,
    Modal, ModalBody
} from 'reactstrap'
import { getKernel } from '@code-202/kernel'

interface Props {}

interface State {}

class ModalDeleteBucket extends React.Component<Props, State> {

    protected bucketStore: Store

    constructor(props: Props) {
        super(props)

        this.bucketStore = getKernel().container.get('bucketStore') as Store
    }

    render () {

        return (
            <Modal
                isOpen={this.bucketStore.deleter.deleting}
                toggle={() => this.bucketStore.deleter.stopDeleting()}
                centered={true}
                >
                <ModalBody>
                    <div className="text-danger mb-2">
                        <FormattedMessage id="app.buckets.delete.question" values={{ name: this.bucketStore.currentBucket ? this.bucketStore.currentBucket.name : '' }}/>
                    </div>
                    { this.bucketStore.deleter.error !== false && this.bucketStore.deleter.error && (
                        <Alert color="warning">
                            <FormattedMessage id={this.bucketStore.deleter.error} />
                        </Alert>
                    )}
                    <div className="d-flex justify-content-between">
                        <Button
                            color="secondary"
                            onClick={() => this.bucketStore.deleter.stopDeleting()}
                            >
                            <FormattedMessage id="app.buckets.delete.cancel" />
                        </Button>
                        <Button
                            color="success"
                            disabled={this.bucketStore.deleter.loader.status === 'pending'}
                            onClick={() => this.bucketStore.deleter.delete()}
                            >
                            <FormattedMessage id="app.buckets.delete.confirm" />
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
        )
    }
}

export default observer(ModalDeleteBucket)
