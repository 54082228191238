import { makeObservable, observable, action } from 'mobx'
import Store from '../store'
import { Navigator } from '../../navigation'

export default class DirectoryCreator {
    protected _store: Store
    protected _navigator: Navigator

    public createModalIsOpen: boolean = false
    public newDirectoryName: string = ''
    public newDirectoryNameError: false | string = false

    constructor (store: Store, navigator: Navigator) {
        makeObservable(this, {
            createModalIsOpen: observable,
            newDirectoryName: observable,
            newDirectoryNameError: observable,

            toggleCreateDirectoryModal: action,
            setNewDirectoryName: action,
            create: action,
        })

        this._store = store
        this._navigator = navigator
    }

    toggleCreateDirectoryModal () {
        this.createModalIsOpen = !this.createModalIsOpen

        if (!this.createModalIsOpen) {
            this.newDirectoryName = ''
            this.newDirectoryNameError = false
        }
    }

    setNewDirectoryName (name: string) {
        this.newDirectoryName = name
    }

    create () {
        this._navigator.redirectTo(`/bucket/${this._store.currentBucket}/${this._store.currentPath ? this._store.currentPath + '/' : ''}${this.newDirectoryName}`)

        this.createModalIsOpen = false
        this.newDirectoryName = ''
        this.newDirectoryNameError = false
    }
}
