import { S3Client } from '@aws-sdk/client-s3'
import { XhrHttpHandler } from '@aws-sdk/xhr-http-handler'
import { Store as SecurityStore } from '../security/store'
import { makeObservable, observable, action, autorun } from 'mobx'
import { ApiRequest, Response } from '@code-202/agent'
import { PrivateLoader } from '@code-202/loader'
import { Denormalizable, Normalizable } from '@code-202/serializer'
import { PrivateLoaderNormalized } from '@code-202/loader/build/private-loader'

export class Store implements Normalizable<StoreNormalized>, Denormalizable<StoreNormalized> {
    protected _securityStore: SecurityStore
    protected _client: S3Client | null = null

    public ready: boolean = false
    public endpoint: string = ''

    protected _getCredentialsRequest: ApiRequest
    protected _getCredentialsLoader: PrivateLoader

    protected _clientInfo: ClientInfo | null = null

    constructor (securityStore: SecurityStore) {

        makeObservable(this, {
            ready: observable,
            endpoint: observable,
        })

        this._securityStore = securityStore

        this._getCredentialsRequest = new ApiRequest(this._securityStore.endpoint + '/credentials', 'GET')
        this._getCredentialsRequest.addAuthorizationService(this._securityStore)
        this._getCredentialsLoader = new PrivateLoader(this._getCredentialsRequest)

        autorun(() => {
            if (this._securityStore.token) {
                this.refresh()
            } else {
                action(() => {
                    this.endpoint = ''
                    this.ready = false
                    this._client = null
                })()
            }
        })
    }

    get loader (): PrivateLoader {
        return this._getCredentialsLoader
    }

    get client (): S3Client | null {
        return this._client
    }

    refresh () {
        if (this._getCredentialsRequest.status !== 'pending') {
            this._getCredentialsRequest.send()
                .then(action((response: Response.Response) => {
                    this.endpoint = response.data.endpoint
                    this._client = new S3Client({
                        endpoint: response.data.endpoint,
                        region: response.data.region,
                        forcePathStyle: true,
                        credentials: {
                            accessKeyId: response.data.accessKeyId,
                            secretAccessKey: response.data.secretAccessKey
                        },
                        requestHandler: typeof document !== 'undefined' ? new XhrHttpHandler({}) : undefined,
                    })

                    this._clientInfo = {
                        endpoint: response.data.endpoint,
                        region: response.data.region,
                        accessKeyId: response.data.accessKeyId,
                        secretAccessKey: response.data.secretAccessKey,
                    }

                    this.ready = true
                }))
                .catch(() => {
                    // Do nothing
                })
        }
    }

    public normalize(): StoreNormalized {
        return {
            ready: this.ready,
            endpoint: this.endpoint,
            client: this._clientInfo,
            loader: this._getCredentialsLoader.normalize()
        }
    }

    public denormalize(data: StoreNormalized): void {
        try {
            action(() => {
                this.ready = data.ready
                this.endpoint = data.endpoint
                if (data.client) {
                    this._clientInfo = data.client
                    this._client = new S3Client({
                        endpoint: data.client.endpoint,
                        region: data.client.region,
                        forcePathStyle: true,
                        credentials: {
                            accessKeyId: data.client.accessKeyId,
                            secretAccessKey: data.client.secretAccessKey
                        }
                    })
                }
                this._getCredentialsLoader.denormalize(data.loader)
            })()
        } catch (e) {
            console.error('Impossible to deserialize : bad data')
        }
    }
}

export interface StoreNormalized {
    ready: boolean
    endpoint: string
    client: ClientInfo | null,
    loader: PrivateLoaderNormalized
}

export interface ClientInfo {
    endpoint: string
    region: string
    accessKeyId: string
    secretAccessKey: string
}