import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { observer } from 'mobx-react'
import Store from './store'
import {
    Button,
    Form, FormFeedback,
    InputGroup, InputGroupText, Input,
    Modal, ModalBody
} from 'reactstrap'
import { getKernel } from '@code-202/kernel'

interface Props {}

interface State {}

class ModalCreaterBucket extends React.Component<Props, State> {
    protected newBucketInput: React.RefObject<HTMLInputElement>
    protected bucketStore: Store

    constructor (props: Props) {
        super(props)

        this.bucketStore = getKernel().container.get('bucketStore') as Store

        this.bucketStore.refresh()

        this.newBucketInput = React.createRef<HTMLInputElement>()
    }

    render () {
        return (
            <Modal
                isOpen={this.bucketStore.creator.creating}
                toggle={() => this.bucketStore.creator.stopCreating()}
                centered={true}
                onOpened={this.onCreateModalOpenedHandler}
                >
                <ModalBody>
                    <Form onSubmit={this.onSubmitCreateHandler}>
                        <InputGroup className="mb-3">
                            <InputGroupText>
                                <FormattedMessage id="app.buckets.add.new_name" />
                            </InputGroupText>
                            <Input
                                type="text"
                                onChange={this.onChangeNewBucketNameHandler}
                                invalid={this.bucketStore.creator.error !== false}
                                innerRef={this.newBucketInput}
                                />
                            { this.bucketStore.creator.error !== false && this.bucketStore.creator.error && (
                                <FormFeedback>
                                    <FormattedMessage id={this.bucketStore.creator.error} />
                                </FormFeedback>
                            )}
                        </InputGroup>
                        <div className="d-flex justify-content-between">
                            <Button
                                color="secondary"
                                onClick={() => this.bucketStore.creator.stopCreating()}
                                >
                                <FormattedMessage id="app.buckets.add.cancel" />
                            </Button>
                            <Button
                                type="submit"
                                color="success"
                                disabled={this.bucketStore.creator.loader.status === 'pending'}
                                >
                                <FormattedMessage id="app.buckets.add.confirm" />
                            </Button>
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        )
    }

    onChangeNewBucketNameHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.bucketStore.creator.setNewBucketName(event.target.value)
    }

    onCreateModalOpenedHandler = () => {
        if (this.newBucketInput.current) {
            this.newBucketInput.current.focus()
        }
    }

    onSubmitCreateHandler = (event: React.FormEvent) => {
        event.preventDefault()

        this.bucketStore.creator.create()
    }
}

export default observer(ModalCreaterBucket)
