import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { observer } from 'mobx-react'
import Store from './store'
import {
    Button,
    Form, FormFeedback,
    Input, InputGroup,
    Modal, ModalBody,
    Progress
} from 'reactstrap'
import { S3Object } from './entities'
import * as cn from 'classnames'
import { mdiCheck, mdiClose, mdiLoading, mdiPencil } from '@mdi/js'
import Icon from '@mdi/react'
import { getKernel } from '@code-202/kernel'

interface Props {
    object: S3Object
}

interface State {}

class ObjectNameView extends React.Component<Props, State> {
    protected newNameInput: React.RefObject<HTMLInputElement>
    protected objectStore: Store

    constructor(props: Props) {
        super(props)

        this.objectStore = getKernel().container.get('objectStore') as Store
        this.newNameInput = React.createRef<HTMLInputElement>()
    }

    componentDidUpdate() {
        if (this.newNameInput.current) {
            this.newNameInput.current.focus()
        }
    }

    render () {
        const { object } = this.props

        if (this.objectStore.objectRenamer.editObject === null) {
            return (
                <>
                    {object.name}
                    <Button
                        color="primary"
                        outline
                        className="border-0 p-0 mx-2"
                        onClick={() => this.objectStore.objectRenamer.setEditObject(object)}
                    >
                        <Icon size={1} path={mdiPencil} />
                    </Button>
                </>
            )
        }

        if (this.objectStore.objectRenamer.status === 'pending') {
            return (
                <>
                    <Icon size={1} path={mdiLoading} spin={true} />
                    <Modal isOpen={true} centered>
                        <ModalBody>
                            <Progress
                            value={this.objectStore.objectRenamer.progress}
                            style={{ height: 20, transition: 'none' }}
                            />
                        </ModalBody>
                    </Modal>
                </>
            )
        }

        if (this.objectStore.objectRenamer.nbUpdate > 0) {
            return <>
                <FormattedMessage id="app.objects.permission.success" values={{ nb: this.objectStore.objectRenamer.nbUpdate }}/>
                <Button
                    color="primary"
                    size="sm"
                    onClick={() => this.objectStore.objectRenamer.toPlainMode()}
                    className="ms-2"
                >
                    <FormattedMessage id="app.objects.permission.clear" />
                </Button>
            </>
        }

        return (
            <Form onSubmit={this.onSubmitCreateHandler}>
                <InputGroup size="sm" className={cn({'is-invalid': this.objectStore.objectRenamer.errors.length > 0})}>
                    <Input
                        type="text"
                        value={this.objectStore.objectRenamer.newName}
                        onChange={this.onNewNameChangeHandler}
                        invalid={this.objectStore.objectRenamer.errors.length > 0}
                        innerRef={this.newNameInput}
                        />
                    <Button
                        color="warning"
                        onClick={() => this.objectStore.objectRenamer.toPlainMode()}
                        >
                        <Icon size={1} path={mdiClose} />
                    </Button>
                    <Button
                        color="success"
                        type="submit"
                        >
                        <Icon size={1} path={mdiCheck} />
                    </Button>
                </InputGroup>
                { this.objectStore.objectRenamer.errors.length > 0 && (
                    <FormFeedback>
                        { this.objectStore.objectRenamer.errors.map((error: string, index: number) => (
                            <FormattedMessage key={index} id={error} />
                        ))}
                    </FormFeedback>
                )}
            </Form>
        )
    }

    onNewNameChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.objectStore.objectRenamer.setNewName(event.target.value)
    }

    onSubmitCreateHandler = (event: React.FormEvent) => {
        event.preventDefault()

        this.objectStore.objectRenamer.rename()
    }
}

export default observer(ObjectNameView)
