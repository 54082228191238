import { Denormalizable, Normalizable } from '@code-202/serializer'
import { action, computed, makeObservable, observable } from 'mobx'
import { Store as EndpointStore } from '../endpoint/store'
import { Navigator } from '../navigation'
import { S3Directory, S3File, S3Object } from './entities'
import * as Parts from './store-parts'
import { ListerNormalized } from './store-parts/lister'
import { ObjectInspectorNormalized } from './store-parts/object-inspector'

export default class Store implements Normalizable<StoreNormalized>, Denormalizable<StoreNormalized> {
    protected _endpointStore: EndpointStore

    public currentBucket: string = ''
    public currentPath: string = ''
    public currentObjects: S3Object[] = []
    public currentMode: 'select' | 'append' | 'list' = 'select'

    protected _lister: Parts.Lister
    protected _directoryCreator: Parts.DirectoryCreator
    protected _objectDeleter: Parts.ObjectDeleter
    protected _objectInspector: Parts.ObjectInspector
    protected _objectUploader: Parts.ObjectUploader
    protected _objectRenamer: Parts.ObjectRenamer
    protected _objectPermissionUpdater: Parts.ObjectPermissionUpdater

    constructor (endpointStore: EndpointStore, navigator: Navigator) {
        makeObservable(this, {
            currentBucket: observable,
            currentPath: observable,
            currentObjects: observable,
            currentMode: observable,

            currentFile: computed,
            currentDirectory: computed,
            currentFiles: computed,
            currentDirectories: computed,

            setCurrentPath: action,
            setCurrentObjects: action,
            toggleCurrentObject: action,
        })

        this._endpointStore = endpointStore

        this._lister = new Parts.Lister(this, this._endpointStore)
        this._directoryCreator = new Parts.DirectoryCreator(this, navigator)
        this._objectDeleter = new Parts.ObjectDeleter(this, this._endpointStore)
        this._objectInspector = new Parts.ObjectInspector(this, this._endpointStore)
        this._objectRenamer = new Parts.ObjectRenamer(this, this._endpointStore)
        this._objectUploader = new Parts.ObjectUploader(this, this._endpointStore)
        this._objectPermissionUpdater = new Parts.ObjectPermissionUpdater(this, this._endpointStore)

        if (typeof document !== 'undefined') {
            document.addEventListener('keydown', this.onKeyDownHandler)
            document.addEventListener('keyup', this.onKeyUpHandler)
        }
    }

    get currentFile(): S3File | undefined
    {
        if (this.currentObjects.length === 1 && this.currentObjects[0].type !== 'dir') {
            return this.currentObjects[0] as S3File
        }
    }

    get currentDirectory(): S3Directory | undefined
    {
        if (this.currentObjects.length === 1 && this.currentObjects[0].type === 'dir') {
            return this.currentObjects[0] as S3Directory
        }
    }

    get currentFiles(): S3File[]
    {
        return this.currentObjects
            .filter((obj: S3Object) => {
                return obj.type !== 'dir'
            })
            .sort((o1: S3Object, o2: S3Object) => {
                if (o1.name === o2.name) {
                    return 0
                }
                return o1.name > o2.name ? 1 : -1
            }) as S3File[]
    }

    get currentDirectories(): S3Directory[]
    {
        return this.currentObjects
            .filter((obj: S3Object) => {
                return obj.type === 'dir'
            })
            .sort((o1: S3Object, o2: S3Object) => {
                if (o1.name === o2.name) {
                    return 0
                }
                return o1.name > o2.name ? 1 : -1
            }) as S3Directory[]
    }

    setCurrentPath (bucket?: string, path?: string) {
        bucket = bucket === undefined ? '' : bucket
        path = path === undefined ? '' : path.replace(/\/$/, '')

        if (this.currentBucket === bucket && this.currentPath === path) {
            return
        }

        this.currentBucket = bucket
        this.currentPath = path

        this.setCurrentObjects([])
        this._lister.refresh()
        this._objectInspector.resetInformations()
    }

    setCurrentObjects (list: S3Object[]) {
        this.currentObjects.splice(0)

        for (const object of list) {
            this.currentObjects.push(object)
        }

        this._objectInspector.getObject()
        this._objectPermissionUpdater.refresh()
        this._objectRenamer.refresh()
    }

    toggleCurrentObject (object: S3Object) {
        const index = this.currentObjects.indexOf(object)
        switch (this.currentMode) {
        case 'select':
            this.currentObjects.splice(0)
            if (index < 0) {
                this.currentObjects.push(object)
            }
            break
        case 'append':
            if (index < 0) {
                this.currentObjects.push(object)
            } else {
                this.currentObjects.splice(index, 1)
            }
            break
        case 'list':
            for (const o of this._lister.getObjects(this.currentObjects[this.currentObjects.length - 1], object)) {

                const index = this.currentObjects.indexOf(o)
                if (index < 0) {
                    this.currentObjects.push(o)
                }
            }
            break
        }

        this._objectInspector.getObject()
        this._objectPermissionUpdater.refresh()
        this._objectRenamer.refresh()
    }

    refresh () {
        this._lister.refresh()
    }

    get lister (): Parts.Lister {
        return this._lister
    }

    get directoryCreator (): Parts.DirectoryCreator {
        return this._directoryCreator
    }

    get objectDeleter (): Parts.ObjectDeleter {
        return this._objectDeleter
    }

    get objectInspector (): Parts.ObjectInspector {
        return this._objectInspector
    }

    get objectRenamer (): Parts.ObjectRenamer {
        return this._objectRenamer
    }

    get objectUploader (): Parts.ObjectUploader {
        return this._objectUploader
    }

    get objectPermissionUpdater (): Parts.ObjectPermissionUpdater {
        return this._objectPermissionUpdater
    }

    onKeyDownHandler = (event: KeyboardEvent) => {
        if (event.key === 'Control' && this.currentMode === 'select') {
            this.currentMode = 'append'
            return
        }

        if (event.key === 'Shift' && this.currentMode === 'select') {
            this.currentMode = 'list'
            return
        }
    }

    onKeyUpHandler = (event: KeyboardEvent) => {
        if (event.key === 'Control' && this.currentMode === 'append') {
            this.currentMode = 'select'
            return
        }

        if (event.key === 'Shift' && this.currentMode === 'list') {
            this.currentMode = 'select'
            return
        }
    }

    public normalize(): StoreNormalized {
        return {
            currentBucket: this.currentBucket,
            currentPath: this.currentPath,
            currentObjects: this.currentObjects,
            currentMode: this.currentMode,
            lister: this._lister.normalize(),
            objectInspector: this._objectInspector.normalize(),
        }
    }

    public denormalize(data: StoreNormalized): void {
        try {
            action(() => {
                this.currentBucket = data.currentBucket
                this.currentPath = data.currentPath
                this.currentObjects = data.currentObjects
                this.currentMode = data.currentMode
                this._lister.denormalize(data.lister)
                this._objectInspector.denormalize(data.objectInspector)
            })()
        } catch (e) {
            console.error('Impossible to deserialize : bad data')
        }
    }
}

export interface StoreNormalized {
    currentBucket: string
    currentPath: string
    currentObjects: S3Object[]
    currentMode: 'select' | 'append' | 'list'
    lister: ListerNormalized
    objectInspector: ObjectInspectorNormalized
}
