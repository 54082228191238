import { makeObservable, observable, action, computed } from 'mobx'
import { Store as EndpointStore } from '../endpoint/store'
import { Store as SecurityStore } from '../security/store'
import { Denormalizable, Normalizable } from '@code-202/serializer'
import * as Parts from './store-parts'
import { Bucket } from './entities'
import { CreatorNormalized } from './store-parts/creator'
import { DeleterNormalized } from './store-parts/deleter'
import { InspectorNormalized } from './store-parts/inspector'
import { ListerNormalized } from './store-parts/lister'
import { PermissionUpdaterNormalized } from './store-parts/permission-updater'
import { CorsUpdaterNormalized } from './store-parts/cors-updater'

export default class Store implements Normalizable<StoreNormalized>, Denormalizable<StoreNormalized> {
    public currentBucket: Bucket | null = null

    protected _securityStore: SecurityStore
    protected _creator: Parts.Creator
    protected _deleter: Parts.Deleter
    protected _inspector: Parts.Inspector
    protected _lister: Parts.Lister
    protected _permissionUpdater: Parts.PermissionUpdater
    protected _corsUpdater: Parts.CorsUpdater

    constructor (securityStore: SecurityStore, endpointStore: EndpointStore) {
        makeObservable(this, {
            currentBucket: observable,

            setCurrentObject: action,
            toggleCurrentObject: action,
        })

        this._securityStore = securityStore

        this._creator = new Parts.Creator(this, this._securityStore)
        this._deleter = new Parts.Deleter(this, this._securityStore)
        this._inspector = new Parts.Inspector(this, this._securityStore, endpointStore)
        this._lister = new Parts.Lister(this, this._securityStore)
        this._permissionUpdater = new Parts.PermissionUpdater(this, this._securityStore, this._inspector)
        this._corsUpdater = new Parts.CorsUpdater(this, this._securityStore, this._inspector)
    }

    setCurrentObject (bucket: Bucket | null) {
        this.currentBucket = bucket

        this._inspector.loadInformations()
        this._permissionUpdater.stopUpdating()
        this._corsUpdater.stopUpdating()
    }

    toggleCurrentObject (bucket: Bucket) {
        if (this.currentBucket === bucket) {
            this.currentBucket = null
        } else {
            this.currentBucket = bucket
        }

        this._inspector.loadInformations()
        this._permissionUpdater.stopUpdating()
        this._corsUpdater.stopUpdating()
    }

    get lister (): Parts.Lister {
        return this._lister
    }

    get creator (): Parts.Creator {
        return this._creator
    }

    get deleter (): Parts.Deleter {
        return this._deleter
    }

    get inspector (): Parts.Inspector {
        return this._inspector
    }

    get permissionUpdater (): Parts.PermissionUpdater {
        return this._permissionUpdater
    }

    get corsUpdater (): Parts.CorsUpdater {
        return this._corsUpdater
    }

    refresh () {
        this._lister.refresh()
    }

    public normalize(): StoreNormalized {
        return {
            currentBucket: this.currentBucket,
            creator: this._creator.normalize(),
            deleter: this._deleter.normalize(),
            inspector: this._inspector.normalize(),
            lister: this._lister.normalize(),
            permissionUpdater: this._permissionUpdater.normalize(),
            corsUpdater: this._corsUpdater.normalize(),
        }
    }

    public denormalize(data: StoreNormalized): void {
        try {
            action(() => {
                this.currentBucket = data.currentBucket
                this._creator.denormalize(data.creator)
                this._deleter.denormalize(data.deleter)
                this._inspector.denormalize(data.inspector)
                this._lister.denormalize(data.lister)
                this._permissionUpdater.denormalize(data.permissionUpdater)
                this._corsUpdater.denormalize(data.corsUpdater)
            })()
        } catch (e) {
            console.error('Impossible to deserialize : bad data')
        }
    }
}

export interface StoreNormalized {
    currentBucket: Bucket | null
    creator: CreatorNormalized
    deleter: DeleterNormalized
    inspector: InspectorNormalized
    lister: ListerNormalized
    permissionUpdater: PermissionUpdaterNormalized
    corsUpdater: CorsUpdaterNormalized
}
