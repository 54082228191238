import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { observer } from 'mobx-react'
import Store from './store'
import { S3Directory, S3File } from './entities'
import { LoaderIndicator } from '@code-202/loader'
import {
    Button,
    Modal, ModalBody
} from 'reactstrap'
import { getKernel } from '@code-202/kernel'

interface Props {}

interface State {}

class ModalDeleteObject extends React.Component<Props, State> {

    protected objectStore: Store

    constructor(props: Props) {
        super(props)

        this.objectStore = getKernel().container.get('objectStore') as Store
    }

    render () {
        return (
            <Modal
                isOpen={this.objectStore.objectDeleter.deleting}
                toggle={this.closeDeleteObjectModal}
                centered={true}
                size="lg"
                >
                <ModalBody>
                    <div className="text-danger mb-2 d-flex flex-column">
                        <FormattedMessage id="app.objects.delete.question"/>

                        { this.objectStore.currentDirectories.length > 0 && (
                            <div>
                                <FormattedMessage id="app.objects.delete.question.directories" values={{ nb: this.objectStore.currentDirectories.length }} />
                                <ul className="">
                                    { this.objectStore.currentDirectories.map((directory: S3Directory, index: number) => (
                                        <li key={index}>
                                            { directory.name }
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}

                        { this.objectStore.currentFiles.length > 0 && (
                            <div>
                                <FormattedMessage id="app.objects.delete.question.files" values={{ nb: this.objectStore.currentFiles.length }} />
                                <ul className="">
                                    { this.objectStore.currentFiles.map((file: S3File, index: number) => (
                                        <li key={index}>
                                            { file.name }
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                    <div className="d-flex justify-content-between">
                        <Button
                            color="secondary"
                            onClick={this.closeDeleteObjectModal}
                            disabled={this.objectStore.objectDeleter.status === 'pending'}
                            >
                            <FormattedMessage id="app.objects.delete.cancel" />
                        </Button>
                        {this.objectStore.objectDeleter.status !== 'waiting' && (
                            <div className="me-2">
                                <LoaderIndicator loadingInformation={this.objectStore.objectDeleter} />
                            </div>
                        )}
                        <Button
                            color="success"
                            disabled={this.objectStore.objectDeleter.status === 'pending'}
                            onClick={this.onDeleteFileConfirmHandler}
                            >
                            <FormattedMessage id="app.objects.delete.confirm" />
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
        )
    }

    closeDeleteObjectModal = () => {
        if (this.objectStore.objectDeleter.status !== 'pending') {
            this.objectStore.objectDeleter.stopDeleting()
        }
    }

    onDeleteFileConfirmHandler = () => {
        this.objectStore.objectDeleter.delete()
    }
}

export default observer(ModalDeleteObject)
