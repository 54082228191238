import { S3Client, ListObjectsCommand, ListObjectsCommandOutput } from '@aws-sdk/client-s3'

export default class KeyLister {
    protected _client: S3Client

    constructor (client: S3Client) {
        this._client = client
    }

    getTrueKeys (keys: string[], bucket: string): Promise<string[]> {
        return new Promise((resolve, reject) => {
            const promises: Promise[] = []

            const list: string[] = []

            for (const key of keys) {
                if (key.substring(key.length - 1) !== '/') {
                    list.push(key)
                } else {
                    const command = new ListObjectsCommand({
                        Bucket: bucket,
                        Prefix: key
                    })

                    const promise = this._client.send(command).then((data: ListObjectsCommandOutput) => {
                        if (data.Contents !== undefined) {
                            for (const k of data.Contents) {
                                if (k.Key) {
                                    list.push(k.Key)
                                }
                            }
                        }
                    }).catch((error) => {
                        console.log(error)
                    })

                    promises.push(promise)
                }
            }

            Promise.all(promises).then(() => {
                resolve(list)
            })
        })
    }
}
