import { makeObservable, observable, action, toJS } from 'mobx'
import Store from '../store'
import { Store as SecurityStore } from '../../security/store'
import { ApiRequest, Response } from '@code-202/agent'
import { PrivateLoader } from '@code-202/loader'
import { Denormalizable, Normalizable } from '@code-202/serializer'
import { CORSRule } from '../entities'
import Inspector from './inspector'
import { PrivateLoaderNormalized } from '@code-202/loader/build/private-loader'

export default class CorsUpdater implements Normalizable<CorsUpdaterNormalized>, Denormalizable<CorsUpdaterNormalized> {
    protected _store: Store
    protected _securityStore: SecurityStore
    protected _inspector: Inspector
    protected _request: ApiRequest
    protected _loader: PrivateLoader

    public updating: boolean = false
    public error: false | string = false
    public updatingIndex: number | null = null
    public updatingAction: 'edit' | 'delete' = 'edit'

    constructor (store: Store, securityStore: SecurityStore, inspector: Inspector) {
        makeObservable(this, {
            updating: observable,
            error: observable,
            updatingIndex: observable,
            updatingAction: observable,

            startUpdating: action,
            stopUpdating: action,
            startEditing: action,
            stopEditing: action,
            startDeleting: action,
            stopDeleting: action,
            addRule: action,
            removeRule: action,
            update: action,
        })

        this._store = store
        this._securityStore = securityStore
        this._inspector = inspector

        this._request = new ApiRequest(this._securityStore.endpoint + '/buckets/{name}/cors', 'PUT')
        this._request.addAuthorizationService(this._securityStore)
        this._loader = new PrivateLoader(this._request)
    }

    get loader (): PrivateLoader {
        return this._loader
    }

    startUpdating () {
        this.updating = true
    }

    stopUpdating () {
        this.updating = false
        this.error = false
    }

    startEditing (index: number) {
        if (!this.updating || !this._inspector.informations || index >=this._inspector.informations.cors.rules.length || index < 0) {
            return
        }

        this.updatingIndex = index
        this.updatingAction = 'edit'
        this._request.reset()
    }

    stopEditing () {
        if (this.loader.status === 'pending') {
            return
        }

        this.updatingIndex = null
    }

    startDeleting (index: number) {
        if (!this.updating || !this._inspector.informations || index >=this._inspector.informations.cors.rules.length || index < 0) {
            return
        }

        this.updatingIndex = index
        this.updatingAction = 'delete'
        this._request.reset()
    }

    stopDeleting () {
        if (this.loader.status === 'pending') {
            return
        }

        this.updatingIndex = null
    }

    addRule () {
        if (!this.updating || !this._inspector.informations) {
            return
        }

        this.updatingIndex = this._inspector.informations.cors.rules.length
        this.updatingAction = 'edit'
    }

    removeRule () {
        if (!this._store.currentBucket || this.updatingIndex === null || !this._inspector.informations) {
            return
        }

        const rules = toJS(this._inspector.informations.cors.rules)
        rules.splice(this.updatingIndex, 1)

        if (this._request.status !== 'pending') {
            this._request
                .setUrlParam('name', this._store.currentBucket.name)
                .send({rules: rules})
                    .then(action(() => {
                        if (this._inspector.informations) {
                            this._inspector.informations.cors.rules = rules
                        }

                        this.stopDeleting()
                    })).catch(action((response: Response.Response) => {
                        this.error = 'app.buckets.show.cors.error'
                    }))
        }

    }

    update (rule: CORSRule) {
        if (!this._store.currentBucket || this.updatingIndex === null || !this._inspector.informations) {
            return
        }

        rule.AllowedHeaders = rule.AllowedHeaders.filter(header => header.length > 0)
        rule.AllowedOrigins = rule.AllowedOrigins.filter(origin => origin.length > 0)

        const rules = toJS(this._inspector.informations.cors.rules)
        rules[this.updatingIndex] = rule

        if (this._request.status !== 'pending') {
            this._request
                .setUrlParam('name', this._store.currentBucket.name)
                .send({rules: rules})
                    .then(action(() => {
                        if (this._inspector.informations) {
                            this._inspector.informations.cors.rules = rules
                        }

                        this.stopEditing()
                    })).catch(action((response: Response.Response) => {
                        this.error = 'app.buckets.show.cors.error'
                    }))
        }
    }

    public normalize(): CorsUpdaterNormalized {
        return {
            updating: this.updating,
            error: this.error,
            updatingIndex: this.updatingIndex,
            updatingAction: this.updatingAction,
            loader: this._loader.normalize(),
        }
    }

    public denormalize(data: CorsUpdaterNormalized): void {
        try {
            action(() => {
                this.updating = data.updating
                this.error = data.error
                this.updatingIndex = data.updatingIndex
                this.updatingAction = data.updatingAction
                this._loader.denormalize(data.loader)
            })()
        } catch (e) {
            console.error('Impossible to deserialize : bad data')
        }
    }
}

export interface CorsUpdaterNormalized {
    updating: boolean
    error: false | string
    updatingIndex: number | null
    updatingAction: 'edit' | 'delete'
    loader: PrivateLoaderNormalized
}
