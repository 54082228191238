import { makeObservable, observable, action, computed, runInAction } from 'mobx'
import Store from '../store'
import { Store as EndpointStore, Loader } from '../../endpoint'
import { LoaderRequest } from '@code-202/loader'
import { S3Object } from '../entities'
import KeyLister from './key-lister'
import { CopyObjectCommand, CopyObjectCommandOutput, DeleteObjectCommand, DeleteObjectCommandOutput } from '@aws-sdk/client-s3'

export default class ObjectRenamer implements LoaderRequest.Informations {
    protected _store: Store
    protected _endpointStore: EndpointStore.Store

    public editObject: S3Object | null = null
    public newName: string = ''
    public nbUpdate: number = 0

    public status: LoaderRequest.Status = 'waiting'
    public progress: number = 0
    public uploadProgress: number = 0
    public errors: string[] = []

    constructor (store: Store, endpointStore: EndpointStore.Store) {
        makeObservable(this, {
            editObject: observable,
            newName: observable,
            nbUpdate: observable,
            status: observable,
            progress: observable,
            uploadProgress: observable,
            errors: observable,

            refresh: action,
            setEditObject: action,
            toPlainMode: action,
            setNewName: action,
            rename: action,
        })

        this._store = store
        this._endpointStore = endpointStore
    }

    refresh () {
        if (this._store.currentObjects.length !== 1 || this.editObject && this._store.currentObjects[0].key !== this.editObject.key) {
            this.toPlainMode()
        }
    }

    setEditObject (object: S3Object) {
        this.editObject = object
        this.newName = this.editObject.name
    }

    toPlainMode = () => {
        this.nbUpdate = 0
        this.editObject = null
    }

    setNewName (name: string) {
        this.newName = name
    }

    async rename () {
        if (!this.editObject || !this._endpointStore.client) {
            return
        }

        if (this.newName.indexOf('/') >= 0) {
            this.errors = ['app.objects.rename.error.bad_name_with_slash']
            return
        }

        if (this.newName === this.editObject.name || !this.newName ) {
            this.toPlainMode()
            return
        }

        if (this.status !== 'pending') {

            this.status = 'pending'
            this.progress = 0
            this.errors.splice(0)

            const kl = new KeyLister(this._endpointStore.client)

            const trueKeys = await kl.getTrueKeys([this.editObject.key], this._store.currentBucket)
                .catch(() => {
                    // Do nothing
                })

            const pairs: {from: string, to: string}[] = []
            const pathLen = this.editObject.key.length
            const newKey: string = this.editObject.path + this.newName + (this.editObject.type === 'dir' ? '/' : '')

            let p = 0
            const promises: Promise[] = []
            if (trueKeys) {
                for (const key of trueKeys) {
                    pairs.push({
                        from: key,
                        to: newKey + key.substring(pathLen)
                    })
                }

                for (const pair of pairs) {
                    const commandCopy = new CopyObjectCommand({
                        Bucket: this._store.currentBucket,
                        Key: pair.to,
                        CopySource: encodeURIComponent(this._store.currentBucket + '/' + pair.from)
                    })

                    const promise = this._endpointStore.client.send(commandCopy).then(action((data: CopyObjectCommandOutput) => {
                        const commandDelete = new DeleteObjectCommand({
                            Bucket: this._store.currentBucket,
                            Key: pair.from
                        })

                        return this._endpointStore.client.send(commandDelete).then(action(() => {
                            p++
                            this.progress = Math.ceil(100 * p / pairs.length)
                        }))
                    })).catch(action((error) => {
                        this.errors.push(error.message)
                    }))

                    promises.push(promise)
                }

            }

            Promise.all(promises).then(action(() => {
                if (this.editObject) {
                    this.editObject.name = this.newName
                    this.editObject.key = newKey
                }

                this.nbUpdate = p
                this.progress = 100

                if (this.errors.length > 0) {
                    this.status = 'error'
                    console.log(this.errors)
                    this.errors = ['app.objects.rename.error.bad_name']
                } else {
                    this.status = 'done'
                }

                this._store.refresh()
            }))

        }
    }
}
