import { makeObservable, observable, action, computed } from 'mobx'
import Store from '../store'
import { Store as EndpointStore } from '../../endpoint'
import { S3Object } from '../entities'
import { LoaderRequest } from '@code-202/loader'
import KeyLister from './key-lister'
import { DeleteObjectsCommand } from '@aws-sdk/client-s3'

export default class ObjectDeleter implements LoaderRequest.Informations {
    protected _store: Store
    protected _endpointStore: EndpointStore.Store

    public deletingObject: S3Object | null = null
    public deleting: boolean = false
    public deletingError: false | string = false

    public status: LoaderRequest.Status = 'waiting'
    public progress: number = 0
    public uploadProgress: number = 0
    public errors: string[] = []

    constructor (store: Store, endpointStore: EndpointStore.Store) {
        makeObservable(this, {
            deletingObject: observable,
            deleting: observable,
            deletingError: observable,
            status: observable,
            progress: observable,
            uploadProgress: observable,
            errors: observable,

            startDeleting: action,
            stopDeleting: action,
            setDeletingObject: action,
            delete: action,
        })

        this._store = store
        this._endpointStore = endpointStore
    }

    startDeleting () {
        this.deleting = true
    }

    stopDeleting () {
        this.deleting = false
        this.deletingError = false
    }

    setDeletingObject (obj: S3Object | null) {
        this.deletingObject = obj

        if (this.deletingObject === null) {
            this.deletingError = false
        }
    }

    delete () {
        if (!this.deleting) {
            this.startDeleting()
            return
        }

        if (!this._endpointStore.client) {
            return
        }

        if (this.status !== 'pending') {
            this.status = 'pending'

            const kl = new KeyLister(this._endpointStore.client)

            kl.getTrueKeys(this._store.currentObjects.map((obj: S3Object) => obj.key), this._store.currentBucket)
                .then((trueKeys: string[]) => {
                    const objects: {Key: string}[] = []
                    for (const key of trueKeys) {
                        objects.push({Key: key})
                    }

                    if (this._endpointStore.client) {
                        const command = new DeleteObjectsCommand({
                            Bucket: this._store.currentBucket,
                            Delete: {
                                Objects: objects
                            }
                        })

                        this._endpointStore.client.send(command).then(action(() => {
                            this.status = 'done'
                            this.stopDeleting()
                            this._store.setCurrentObjects([])
                            this._store.refresh()
                        })).catch(action((error) => {
                            this.status = 'error'
                            this.deletingError = error.message
                        }))
                    }
                })
        }
    }
}
