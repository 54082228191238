import * as React from 'react'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import { observer } from 'mobx-react'
import Store from './store'
import {
    Button,
    Form,
    Input, InputGroup
} from 'reactstrap'
import { Informations, S3BucketPublicPermission } from './entities'
import * as cn from 'classnames'
import { getKernel } from '@code-202/kernel'
import { mdiCheck, mdiClose, mdiLoading, mdiPencil } from '@mdi/js'
import Icon from '@mdi/react'

interface Props extends WrappedComponentProps<'intl'> {
    informations?: Informations | null
}

interface State {}

class BucketPermissionView extends React.Component<Props, State> {

    protected bucketStore: Store

    constructor(props: Props) {
        super(props)

        this.bucketStore = getKernel().container.get('bucketStore') as Store
    }

    render () {
        const { informations, intl } = this.props

        if (!this.bucketStore || !informations) {
            return null
        }

        if (!this.bucketStore.permissionUpdater.updating) {
            return (
                <>
                    <FormattedMessage id={`app.objects.permission.values.${informations.acl.publicPermission}`} />
                    <Button
                        color="primary"
                        outline
                        className="border-0 p-0 mx-2"
                        onClick={() => this.bucketStore.permissionUpdater.startUpdating()}
                    >
                        <Icon size={1} path={mdiPencil} />
                    </Button>
                </>
            )
        }

        if (this.bucketStore.permissionUpdater.loader.status === 'pending') {
            return (
                <Icon size={1} path={mdiLoading} spin={true} />
            )
        }

        if (this.bucketStore.permissionUpdater.error) {
            return <>
                <span className="text-danger me-2">
                    <FormattedMessage id={this.bucketStore.permissionUpdater.error}/>
                </span>
                <Button
                    color="primary"
                    size="sm"
                    onClick={() => this.bucketStore.permissionUpdater.stopUpdating()}
                    className="ms-2"
                >
                    <FormattedMessage id="app.buckets.show.permission.clear" />
                </Button>
            </>
        }

        return (
            <Form onSubmit={this.onSubmitCreateHandler}>
                <InputGroup size="sm" className={cn({'is-invalid': this.bucketStore.permissionUpdater.loader.status === 'error'})}>
                    <Input
                        type="select"
                        value={this.bucketStore.permissionUpdater.newPermission}
                        onChange={this.onNewPermissionChangeHandler}
                        >
                        <option value="PRIVATE">
                            { intl.formatMessage({ id: 'app.buckets.show.permission.values.PRIVATE' }) }
                        </option>
                        <option value="READ">
                            { intl.formatMessage({ id: 'app.buckets.show.permission.values.READ' }) }
                        </option>
                        <option value="WRITE">
                            { intl.formatMessage({ id: 'app.buckets.show.permission.values.WRITE' }) }
                        </option>
                    </Input>
                    <Button
                        color="warning"
                        onClick={() => this.bucketStore.permissionUpdater.stopUpdating()}
                        >
                        <Icon size={1} path={mdiClose} />
                    </Button>
                    <Button
                        color="success"
                        type="submit"
                        >
                        <Icon size={1} path={mdiCheck} />
                    </Button>
                </InputGroup>
            </Form>
        )
    }

    onNewPermissionChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.bucketStore.permissionUpdater.setNewPermission(event.target.value as S3BucketPublicPermission)
    }

    onSubmitCreateHandler = (event: React.FormEvent) => {
        event.preventDefault()

        this.bucketStore.permissionUpdater.update()
    }
}

export default injectIntl(observer(BucketPermissionView))
