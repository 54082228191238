import { LocaleStore, RemoteCatalog } from '@code-202/intl'
import { getKernel } from '@code-202/kernel'
import * as Security from './security'
import { TokenVerifier, KeyProvider, KeyBuilder } from '@code-202/jwt'
import * as moment from 'moment'
import 'moment-timezone'
import * as Endpoint from './endpoint'
import * as Bucket from './bucket'
import * as Object from './object'
import { Navigator } from './navigation'

export const buildContainer = (req: { cookies?: string }): void => {
    const kernel = getKernel()

    const localeStore: LocaleStore = new LocaleStore(['fr'])

    localeStore.addCatalog(new RemoteCatalog('fr', kernel.manifest.get('translations/app.fr.json', true), ['app']))

    kernel.container.onInit(() => {
        localeStore.changeLocale('fr').catch((err) => console.error(err))
    })

    kernel.container.add('intl.locale', localeStore)

    moment.locale('fr')
    moment.tz.setDefault('Europe/Paris')

    const securityStore = new Security.Store.Store(
        new TokenVerifier(new KeyProvider(new KeyBuilder.SPKIBuilder(kernel.environment.get('PUBLIC_KEY') as string, 'RS256'))),
        {
            endpoint: kernel.environment.get('API_ENDPOINT') as string,
            cookieOptions: {
                domain: kernel.environment.get('MAIN_DOMAIN') as string,
            },
            notifyLogout: false,
        }
    )
    kernel.container.add('security', securityStore)

    const endpointStore = new Endpoint.Store.Store(securityStore)
    kernel.container.add('endpoint', endpointStore)

    const navigator = new Navigator()
    kernel.container.add('navigator', navigator)

    kernel.container.add('bucketStore', new Bucket.Store(securityStore, endpointStore))

    kernel.container.add('objectStore', new Object.Store(endpointStore, navigator))
}
