import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { observer } from 'mobx-react'
import Store from './store'
import { LoaderIndicator } from '@code-202/loader'
import { Uploading } from './store-parts/object-uploader'
import {
    Button,
    Collapse,
    Card,
    ListGroup, ListGroupItem,
    Progress
} from 'reactstrap'
import * as cn from 'classnames'
import { mdiArrowDownDropCircleOutline, mdiArrowUpDropCircleOutline, mdiCancel, mdiCheck, mdiExclamationThick, mdiLoading, mdiTrashCanOutline } from '@mdi/js'
import Icon from '@mdi/react'
import { getKernel } from '@code-202/kernel'

interface Props {}

interface State {}

class Uploader extends React.Component<Props, State> {

    protected objectStore: Store

    constructor(props: Props) {
        super(props)

        this.objectStore = getKernel().container.get('objectStore') as Store
    }

    render () {

        return (
            <div className="d-inline-block position-relative">

                <Button
                    onClick={() => this.objectStore.objectUploader.toggleUploader()}
                    outline
                    >
                    <Icon path={
                        this.objectStore.objectUploader.status === 'error' ? mdiExclamationThick :
                        this.objectStore.objectUploader.status === 'pending' ? mdiLoading :
                        this.objectStore.objectUploader.status.substr(0, 4) !== 'done' ? mdiCheck :
                        ''
                    } spin={this.objectStore.objectUploader.status === 'pending'} className={cn('me-2', {'text-danger': this.objectStore.objectUploader.status === 'error'})} size={1} />
                    <FormattedMessage id="app.objects.uploader.title" />
                    <Icon path={
                        this.objectStore.objectUploader.uploaderIsOpen ? mdiArrowUpDropCircleOutline : mdiArrowDownDropCircleOutline
                    } spin={this.objectStore.objectUploader.status === 'pending'} className={cn('ms-2')} size={1} />
                </Button>
                <Collapse
                    isOpen={this.objectStore.objectUploader.uploaderIsOpen}
                    className="position-absolute"
                    style={{ minWidth: 400, top: '100%', right: 0, zIndex: 1000 }}
                >
                    <Card className="mt-2">
                        { this.objectStore.objectUploader.uploadings.length === 0 ? (
                            <span className="text-info text-center m-3">
                                <FormattedMessage id="app.object.uploader.no_uploading" />
                            </span>
                        ) : (
                            <ListGroup flush>
                                { this.objectStore.objectUploader.uploadings.map((uploading: Uploading, index: number) => (
                                    <ListGroupItem key={index}>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                { uploading.name }
                                            </div>
                                            <div>
                                                <LoaderIndicator loadingInformation={uploading.loader} />
                                                { uploading.loader.status === 'pending' ? (
                                                    <Button
                                                        color="danger"
                                                        outline
                                                        className="border-0 p-0 mx-2"
                                                        onClick={() => this.objectStore.objectUploader.cancel(uploading)}
                                                    >
                                                        <Icon size={1} path={mdiCancel} />
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        color="danger"
                                                        outline
                                                        className="border-0 p-0 mx-2"
                                                        onClick={() => this.objectStore.objectUploader.remove(uploading)}
                                                    >
                                                        <Icon size={1} path={mdiTrashCanOutline} />
                                                    </Button>
                                                )}
                                            </div>
                                        </div>
                                        <Progress
                                            value={uploading.loader.uploadProgress}
                                            style={{ height: 5 }}
                                            className={cn({'bg-danger': uploading.loader.status === 'error'})}
                                            />
                                        { uploading.loader.status === 'error' && (
                                            <>
                                                { uploading.loader.errors.map((error: string, index: number) => (
                                                    <span key={index} className="text-danger">
                                                        { error }
                                                    </span>
                                                ))}
                                            </>
                                        )}
                                    </ListGroupItem>
                                ))}
                            </ListGroup>
                        )}
                    </Card>
                </Collapse>
            </div>
        )
    }
}

export default observer(Uploader)
