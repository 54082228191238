import { makeObservable, observable, action, computed, runInAction } from 'mobx'
import Store from '../store'
import { Store as EndpointStore } from '../../endpoint'
import { LoaderRequest } from '@code-202/loader'
import { S3Object, S3Directory, S3FileAdvanced, S3ObjectPublicPermission } from '../entities'
import KeyLister from './key-lister'
import { PutObjectAclCommand, PutObjectAclCommandOutput } from '@aws-sdk/client-s3'

export default class ObjectPermissionUpdater implements LoaderRequest.Informations {
    protected _store: Store
    protected _endpointStore: EndpointStore.Store

    public editObject: S3Directory | S3FileAdvanced | null = null
    public newPermission: S3ObjectPublicPermission = 'PRIVATE'
    public error: false | string = false
    public nbUpdate: number = 0

    public status: LoaderRequest.Status = 'waiting'
    public progress: number = 0
    public uploadProgress: number = 0
    public errors: string[] = []

    constructor (store: Store, endpointStore: EndpointStore.Store) {
        makeObservable(this, {
            editObject: observable,
            newPermission: observable,
            error: observable,
            nbUpdate: observable,
            status: observable,
            progress: observable,
            uploadProgress: observable,
            errors: observable,

            refresh: action,
            setEditObject: action,
            toPlainMode: action,
            setNewPermission: action,
            update: action,
        })

        this._store = store
        this._endpointStore = endpointStore
    }

    refresh () {
        if (this._store.currentObjects.length !== 1 || this.editObject && this._store.currentObjects[0].key !== this.editObject.key) {
            this.toPlainMode()
        }
    }

    setEditObject (object: S3Directory | S3FileAdvanced) {
        this.editObject = object
        this.newPermission = this.editObject.type !== 'dir' ? this.editObject.publicPermission : 'PRIVATE'
    }

    toPlainMode = () => {
        this.nbUpdate = 0
        this.error = false
        this.editObject = null
    }

    setNewPermission (permission: S3ObjectPublicPermission) {
        this.newPermission = permission
    }

    async update () {
        if (!this.editObject || !this.newPermission || !this._endpointStore.client) {
            return
        }

        if (this.editObject.type === 'file' && this.editObject.publicPermission === this.newPermission) {
            this.toPlainMode()
            return
        }

        if (this.status !== 'pending') {

            this.status = 'pending'
            this.progress = 0
            this.errors.splice(0)

            let acl = 'private';
            switch (this.newPermission) {
                case 'READ':
                    acl = 'public-read';
                    break;
                case 'WRITE':
                    acl = 'public-read-write';
                    break;
            }

            const kl = new KeyLister(this._endpointStore.client)

            const trueKeys = await kl.getTrueKeys([this.editObject.key], this._store.currentBucket)
                .catch(() => {
                    // Do nothing
                })

            let p = 0
            const promises: Promise[] = []
            if (trueKeys) {
                for (const key of trueKeys) {

                    const command = new PutObjectAclCommand({
                        Bucket: this._store.currentBucket,
                        Key: key,
                        ACL: acl
                    })

                    const promise = this._endpointStore.client.send(command).then(action((data: PutObjectAclCommandOutput) => {
                        p++
                        this.progress = Math.ceil(100 * p / trueKeys.length)
                    })).catch(action((error) => {
                        this.errors.push(error.message)
                    }))

                    promises.push(promise)
                }
            }

            Promise.all(promises).then(action(() => {
                this.nbUpdate = p

                if (this.editObject && this.editObject.type !== 'dir') {
                    this.editObject.publicPermission = this.newPermission
                }

                this.progress = 100

                if (this.errors.length > 0) {
                    this.status = 'error'
                } else {
                    this.status = 'done'
                }
            }))
        }
    }
}
