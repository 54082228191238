import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { observer } from 'mobx-react'
import Store from './store'
import { Loader } from '@code-202/loader'
import {
    Alert,
    Button,
    Table
} from 'reactstrap'
import Moment from 'react-moment'
import { filesize } from 'filesize'
import ObjectNameView from './object-name-view'
import FilePermissionView from './file-permission-view'
import DirectoryPermissionView from './directory-permission-view'
import Icon from '@mdi/react'
import { mdiBroom, mdiCloudDownloadOutline, mdiCloudSearchOutline, mdiDownloadOutline, mdiOpenInNew, mdiTrashCanOutline } from '@mdi/js'
import { getKernel } from '@code-202/kernel'

interface Props {}

interface State {}

class ShowObjectView extends React.Component<Props, State> {

    protected objectStore: Store

    constructor(props: Props) {
        super(props)

        this.objectStore = getKernel().container.get('objectStore') as Store
    }

    render () {
        if (this.objectStore.currentObjects.length === 0) {
            return (
                <Alert color="info">
                    <FormattedMessage id="app.objects.show.current.empty" />
                </Alert>
            )
        }

        if (this.objectStore.currentFile) {
            return (
                <Loader loadingInformation={this.objectStore.objectInspector.getObjectLoader}>
                    <div className="d-flex flex-column w-100 h-100">
                        { this.objectStore.objectInspector.informations !== null && (
                            <>
                                <Table striped>
                                    <tbody>
                                        { this.objectStore.currentFile && (
                                            <>
                                                <tr>
                                                    <th>
                                                        <FormattedMessage id="app.objects.show.file.name" />
                                                    </th>
                                                    <td>
                                                        <ObjectNameView object={this.objectStore.currentFile} />
                                                    </td>
                                                    <td>
                                                        <Button
                                                            color="danger"
                                                            outline
                                                            className="border-0 p-0 mx-2"
                                                            onClick={() => this.objectStore.objectDeleter.startDeleting()}
                                                        >
                                                            <Icon size={1} path={mdiTrashCanOutline} />
                                                        </Button>
                                                    </td>
                                                </tr>
                                            </>
                                        )}
                                        <tr>
                                            <th>
                                                <FormattedMessage id="app.objects.show.file.content-type" />
                                            </th>
                                            <td>
                                                { this.objectStore.objectInspector.informations.contentType }
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th>
                                                <FormattedMessage id="app.objects.show.file.content-length" />
                                            </th>
                                            <td>
                                                { filesize(this.objectStore.objectInspector.informations.size) }
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th>
                                                <FormattedMessage id="app.objects.show.file.last-modified" />
                                            </th>
                                            <td>
                                                <Moment format="D MMM YYYY HH:mm:ss">{ this.objectStore.objectInspector.informations.lastModified.toISOString() }</Moment>
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th>
                                                <FormattedMessage id="app.objects.show.file.public-permission" />
                                            </th>
                                            <td>
                                                <FilePermissionView file={this.objectStore.objectInspector.informations} />
                                            </td>
                                            <td></td>
                                        </tr>
                                        { this.objectStore.objectInspector.informations.publicPermission !== 'PRIVATE' && (
                                            <tr>
                                                <th>
                                                    <FormattedMessage id="app.objects.show.file.link" />
                                                </th>
                                                <td>
                                                    <a
                                                        href={this.objectStore.objectInspector.publicUrl}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        >
                                                        <Icon size={1} path={mdiOpenInNew} />
                                                    </a>
                                                </td>
                                                <td></td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                                <div className="flex-fill d-flex justify-content-center align-items-center overflow-hidden position-relative">
                                    { this.objectStore.objectInspector.enablePreview ? (
                                        <>
                                            { this.objectStore.objectInspector.autoLoadContent && this.objectStore.objectInspector.privateUrl ? (
                                                <>
                                                    <object
                                                    data={this.objectStore.objectInspector.privateUrl}
                                                    className={this.objectStore.objectInspector.informations.type === 'img' ? 'mw-100 mh-100' : 'w-100 h-100'}
                                                    style={{ objectFit: 'contain'}}
                                                    />
                                                    <Button
                                                        className="m-2 position-absolute"
                                                        style={{ top: 0, left: 0 }}
                                                        color="primary"
                                                        size="lg"
                                                        onClick={() => this.objectStore.objectInspector.download()}
                                                    >
                                                        <Icon size={1} path={mdiCloudDownloadOutline} />
                                                    </Button>
                                                </>
                                            ) : (
                                                <>
                                                    <Button
                                                        color="primary"
                                                        className="m-2"
                                                        size="lg"
                                                        onClick={() => this.objectStore.objectInspector.enableLoadContent() }
                                                    >
                                                        <Icon size={1} path={mdiCloudSearchOutline} />
                                                    </Button>
                                                    <Button
                                                        className="m-2"
                                                        color="primary"
                                                        size="lg"
                                                        onClick={() => this.objectStore.objectInspector.download()}
                                                    >
                                                        <Icon size={1} path={mdiDownloadOutline} />
                                                    </Button>
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <Button
                                            className="m-2"
                                            color="primary"
                                            size="lg"
                                            onClick={() => this.objectStore.objectInspector.download()}
                                        >
                                            <Icon size={1} path={mdiCloudDownloadOutline} />
                                        </Button>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </Loader>
            )
        }

        if (this.objectStore.currentDirectory)
        {
            return (
                <div className="d-flex flex-column w-100 h-100">
                    <Table striped>
                        <tbody>
                            { this.objectStore.currentDirectory && (
                                <>
                                    <tr>
                                        <th>
                                            <FormattedMessage id="app.objects.show.directory.name" />
                                        </th>
                                        <td>
                                            <ObjectNameView object={this.objectStore.currentDirectory} />
                                        </td>
                                        <td>
                                            <Button
                                                color="danger"
                                                outline
                                                className="border-0 p-0 mx-2"
                                                onClick={() => this.objectStore.objectDeleter.startDeleting()}
                                            >
                                                <Icon size={1} path={mdiTrashCanOutline} />
                                            </Button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            <FormattedMessage id="app.objects.show.directory.permission" />
                                        </th>
                                        <td colSpan={2}>
                                            <DirectoryPermissionView directory={this.objectStore.currentDirectory} />
                                        </td>
                                    </tr>
                                </>
                            )}
                        </tbody>
                    </Table>
                </div>
            )
        }

        const nbFiles = this.objectStore.currentFiles.length
        const nbDirectories = this.objectStore.currentDirectories.length

        return (
            <div className="d-flex flex-column">
                { nbDirectories > 0 && (
                    <div className="text-center">
                        <FormattedMessage id="app.objects.show.current.directories" values={{ nb: nbDirectories }} />
                    </div>
                )}
                { nbFiles > 0 && (
                    <div className="text-center">
                        <FormattedMessage id="app.objects.show.current.files" values={{ nb: nbFiles }} />
                    </div>
                )}
                <Button
                    color="primary"
                    onClick={() => this.objectStore.setCurrentObjects([])}
                    className="mt-3"
                    >
                    <Icon size={1} path={mdiBroom} className="me-2" />
                    <FormattedMessage id="app.objects.show.current.clear" />
                </Button>

                <Button
                    color="danger"
                    onClick={() => this.objectStore.objectDeleter.delete()}
                    className="mt-3"
                    >
                    <Icon size={1} path={mdiTrashCanOutline} className="me-2" />
                    <FormattedMessage id="app.objects.show.current.delete" />
                </Button>
            </div>
        )
    }
}

export default observer(ShowObjectView)
