import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { observer } from 'mobx-react'
import Store from './store'
import {
    Button,
    Container, Row, Col
} from 'reactstrap'
import { CORSRule } from './entities'
import { getKernel } from '@code-202/kernel'
import Icon from '@mdi/react'
import { mdiPencil, mdiTrashCanOutline } from '@mdi/js'

interface Props {
    rule: CORSRule
    index: number
}

interface State {}

class BucketCorsRuleView extends React.Component<Props, State> {

    protected bucketStore: Store

    constructor(props: Props) {
        super(props)

        this.bucketStore = getKernel().container.get('bucketStore') as Store
    }

    render () {
        const { rule, index } = this.props

        if (!this.bucketStore || !rule) {
            return null
        }

        return (
            <Container>
                <Row>
                    <Col sm={4}>
                        <b><FormattedMessage id="app.buckets.show.cors.allowed-headers" /> : </b>
                    </Col>
                    <Col>
                        <ul className="list-unstyled">
                            { rule.AllowedHeaders.map((header: string, i: number) => (
                                <li key={i}>
                                    { header }
                                </li>
                            ))}
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col sm={4}>
                        <b><FormattedMessage id="app.buckets.show.cors.allowed-methods" /> : </b>
                    </Col>
                    <Col>
                        { rule.AllowedMethods.join(' | ') }
                    </Col>
                </Row>
                <Row>
                    <Col sm={4}>
                        <b><FormattedMessage id="app.buckets.show.cors.allowed-origins" /> : </b>
                    </Col>
                    <Col>
                        <ul className="list-unstyled">
                            { rule.AllowedOrigins.map((origin: string, i: number) => (
                                <li key={i}>
                                    { origin }
                                </li>
                            ))}
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col sm={4}>
                        <b><FormattedMessage id="app.buckets.show.cors.max-age-seconds" /> : </b>
                    </Col>
                    <Col>
                        { rule.MaxAgeSeconds }
                    </Col>
                </Row>
                { this.bucketStore.corsUpdater.updating && (
                    <div className="position-absolute m-2 d-flex flex-column" style={{ top: 0, right: 0 }}>
                        <Button
                            color="primary"
                            outline
                            className="border-0 p-0"
                            onClick={() => this.bucketStore.corsUpdater.startEditing(index)}
                        >
                            <Icon size={1} path={mdiPencil} />
                        </Button>
                        <Button
                            color="danger"
                            outline
                            className="border-0 p-0 mt-2"
                            onClick={() => this.bucketStore.corsUpdater.startDeleting(index)}
                        >
                            <Icon size={1} path={mdiTrashCanOutline} />
                        </Button>
                    </div>
                )}
            </Container>
        )
    }
}

export default observer(BucketCorsRuleView)
