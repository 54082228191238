import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { observer } from 'mobx-react'
import { NavigateFunction } from 'react-router-dom'
import Store from './store'
import { LoaderIndicator } from '@code-202/loader'
import { withNavigate } from '../router'
import { Bucket } from './entities'
import {
    Button,
    Table
} from 'reactstrap'
import * as cn from 'classnames'
import ShowBucketView from './show-bucket-view'
import ModalCreateBucket from './modal-create-bucket'
import ModalDeleteBucket from './modal-delete-bucket'
import { getKernel } from '@code-202/kernel'
import { mdiFolderHomeOutline, mdiPlusThick, mdiRefresh } from '@mdi/js'
import Icon from '@mdi/react'

interface Props {
    navigate?: NavigateFunction
}

interface State {}

class ListView extends React.Component<Props, State> {
    protected bucketStore: Store

    constructor (props: Props) {
        super(props)

        this.bucketStore = getKernel().container.get('bucketStore') as Store

        this.bucketStore.lister.refresh()
    }

    render () {
        const { navigate } = this.props

        if (!navigate) {
            return null
        }

        return (
            <div className="h-100 d-flex flex-column">
                <div className="d-flex justify-content-end p-2">
                    <Button
                        color="primary"
                        onClick={() => this.bucketStore.lister.refresh()}
                        className="me-2"
                        >
                        <Icon size={1} path={mdiRefresh} className="me-2" />
                        <FormattedMessage id="app.buckets.refresh" />
                    </Button>
                    <Button
                        color="success"
                        onClick={() => this.bucketStore.creator.startCreating()}
                        >
                        <Icon size={1} path={mdiPlusThick} className="me-2" />
                        <FormattedMessage id="app.buckets.add" />
                    </Button>
                </div>
                <div className="d-flex flex-fill overflow-hidden">
                    <div className="w-50 overflow-auto flex-fill d-flex flex-column position-relative">
                        <div className="position-absolute" style={{ top: 10, right: 10 }}>
                            <LoaderIndicator loadingInformation={this.bucketStore.lister.loader} />
                        </div>

                        <Table className="noselect">
                            <thead>
                                <tr>
                                    <th>
                                        <FormattedMessage id="app.buckets.list.name" />
                                    </th>
                                </tr>
                            </thead>
                            { this.bucketStore.lister.buckets.length > 0 && (
                                <tbody>
                                    { this.bucketStore.lister.buckets.map((bucket: Bucket, index: number) => (
                                        <tr key={index}
                                            onDoubleClick={() => navigate(`/bucket/${bucket.name}`)}
                                            onClick={() => this.bucketStore.toggleCurrentObject(bucket)}
                                            className={cn({'table-primary': this.bucketStore.currentBucket === bucket })}
                                            >
                                            <td>
                                                <Icon size={1} path={mdiFolderHomeOutline} className="me-2"/>
                                                { bucket.name }
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            )}
                        </Table>
                    </div>
                    <div className="w-50 flex-fill d-flex justify-content-center align-items-center border-start">
                        <ShowBucketView />
                    </div>
                </div>
                <ModalCreateBucket />
                <ModalDeleteBucket />
            </div>
        )
    }
}

export default withNavigate(observer(ListView))
