import { makeObservable, observable, action } from 'mobx'
import Store from '../store'
import { Store as SecurityStore } from '../../security/store'
import { ApiRequest, Response } from '@code-202/agent'
import { PrivateLoader } from '@code-202/loader'
import { Denormalizable, Normalizable } from '@code-202/serializer'
import { S3BucketPublicPermission } from '../entities'
import Inspector from './inspector'
import { PrivateLoaderNormalized } from '@code-202/loader/build/private-loader'

export default class PermissionUpdater implements Normalizable<PermissionUpdaterNormalized>, Denormalizable<PermissionUpdaterNormalized> {
    protected _store: Store
    protected _securityStore: SecurityStore
    protected _inspector: Inspector
    protected _request: ApiRequest
    protected _loader: PrivateLoader

    public updating: boolean = false
    public newPermission: S3BucketPublicPermission = 'PRIVATE'
    public error: false | string = false

    constructor (store: Store, securityStore: SecurityStore, inspector: Inspector) {
        makeObservable(this, {
            updating: observable,
            newPermission: observable,
            error: observable,

            startUpdating: action,
            stopUpdating: action,
            setNewPermission: action,
            update: action,
        })

        this._store = store
        this._securityStore = securityStore
        this._inspector = inspector

        this._request = new ApiRequest(this._securityStore.endpoint + '/buckets/{name}/permission', 'PUT')
        this._request.addAuthorizationService(this._securityStore)
        this._loader = new PrivateLoader(this._request)
    }

    get loader (): PrivateLoader {
        return this._loader
    }

    startUpdating () {
        this.updating = true
        if (this._inspector.informations) {
            this.newPermission = this._inspector.informations.acl.publicPermission
        }
    }

    stopUpdating () {
        this.updating = false
        this.error = false
    }

    setNewPermission (permission: S3BucketPublicPermission) {
        this.newPermission = permission
    }

    update () {
        if (!this._store.currentBucket) {
            return
        }

        if (this._inspector.informations && this.newPermission === this._inspector.informations.acl.publicPermission) {
            this.stopUpdating()
            return
        }

        if (this._request.status !== 'pending') {
            this._request
                .setUrlParam('name', this._store.currentBucket.name)
                .send({permission: this.newPermission})
                    .then(action(() => {
                        if (this._inspector.informations) {
                            this._inspector.informations.acl.publicPermission = this.newPermission
                        }

                        this.stopUpdating()
                    })).catch(action((response: Response.Response) => {
                        this.error = 'app.buckets.show.permission.error'
                    }))
        }
    }

    public normalize(): PermissionUpdaterNormalized {
        return {
            updating: this.updating,
            newPermission: this.newPermission,
            error: this.error,
            loader: this._loader.normalize(),
        }
    }

    public denormalize(data: PermissionUpdaterNormalized): void {
        try {
            action(() => {
                this.updating = data.updating
                this.newPermission = data.newPermission
                this.error = data.error
                this._loader.denormalize(data.loader)
            })()
        } catch (e) {
            console.error('Impossible to deserialize : bad data')
        }
    }
}

export interface PermissionUpdaterNormalized {
    updating: boolean
    newPermission: S3BucketPublicPermission
    error: false | string
    loader: PrivateLoaderNormalized
}
