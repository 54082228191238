import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { observer } from 'mobx-react'
import Store from './store'
import { LoaderIndicator } from '@code-202/loader'
import {
    Button,
    ListGroup, ListGroupItem,
    Modal, ModalBody
} from 'reactstrap'
import { CORS, CORSRule } from './entities'
import BucketCorsRuleView from './bucket-cors-rule-view'
import FormEditCorsRule from './form-edit-cors-rules'
import { getKernel } from '@code-202/kernel'
import { mdiClose, mdiPencil, mdiPlus } from '@mdi/js'
import Icon from '@mdi/react'

interface Props {
    cors: CORS
}

interface State {}

class BucketCorsView extends React.Component<Props, State> {

    protected bucketStore: Store

    constructor(props: Props) {
        super(props)

        this.bucketStore = getKernel().container.get('bucketStore') as Store
    }

    render () {
        const { cors } = this.props

        if (!this.bucketStore || !cors) {
            return null
        }

        return (
            <>
                { cors.rules.length === 0 ? (
                    <div>
                        <FormattedMessage id="app.buckets.show.cors.empty" />
                    </div>
                ) : (
                    <ListGroup>
                        { cors.rules.map((rule: CORSRule, index: number) => (
                            <ListGroupItem key={index}>
                                <BucketCorsRuleView rule={rule} index={index}/>
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                )}
                <div>
                    { this.bucketStore.corsUpdater.updating ? (
                        <>
                            <Button
                                color="primary"
                                outline
                                className="border-0 p-0 me-2"
                                onClick={() => this.bucketStore.corsUpdater.stopUpdating()}
                            >
                                <Icon size={1} path={mdiClose} />
                            </Button>
                            <Button
                                color="success"
                                outline
                                className="border-0 p-0 me-2"
                                onClick={() => this.bucketStore.corsUpdater.addRule()}
                            >
                                <Icon size={1} path={mdiPlus} />
                            </Button>
                        </>
                    ) : (
                        <Button
                            color="primary"
                            outline
                            className="border-0 p-0 me-2"
                            onClick={() => this.bucketStore.corsUpdater.startUpdating()}
                        >
                            <Icon size={1} path={mdiPencil} />
                        </Button>
                    )}
                </div>

                <Modal isOpen={this.bucketStore.corsUpdater.updatingIndex !== null && this.bucketStore.corsUpdater.updatingAction === 'edit'} toggle={() => this.bucketStore.corsUpdater.stopEditing()} centered>
                    <ModalBody>
                        { this.bucketStore.corsUpdater.updatingIndex !== null && (
                            <FormEditCorsRule index={this.bucketStore.corsUpdater.updatingIndex} />
                        )}
                    </ModalBody>
                </Modal>

                <Modal isOpen={this.bucketStore.corsUpdater.updatingIndex !== null && this.bucketStore.corsUpdater.updatingAction === 'delete'} toggle={() => this.bucketStore.corsUpdater.stopDeleting()} centered>
                    <ModalBody>
                        <div className="text-danger mb-2">
                            <FormattedMessage id="app.buckets.show.cors.delete_rule.question" />
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <Button
                                color="secondary"
                                onClick={() => this.bucketStore.corsUpdater.stopDeleting()}
                                disabled={this.bucketStore.corsUpdater.loader.status === 'pending'}
                                >
                                <FormattedMessage id="app.buckets.show.cors.delete_rule.cancel" />
                            </Button>
                            {this.bucketStore.corsUpdater.loader.status !== 'waiting' && (
                                <div className="me-2">
                                    <LoaderIndicator loadingInformation={this.bucketStore.corsUpdater.loader} />
                                </div>
                            )}
                            <Button
                                color="success"
                                disabled={this.bucketStore.corsUpdater.loader.status === 'pending'}
                                onClick={() => this.bucketStore.corsUpdater.removeRule()}
                                >
                                <FormattedMessage id="app.buckets.show.cors.delete_rule.confirm" />
                            </Button>
                        </div>
                    </ModalBody>
                </Modal>
            </>
        )
    }
}

export default observer(BucketCorsView)
