import { getKernel } from '@code-202/kernel'
import { Loader } from '@code-202/loader'
import { mdiAccount, mdiCloudSearchOutline, mdiLogout } from '@mdi/js'
import Icon from '@mdi/react'
import { observer } from 'mobx-react'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link, Outlet, Route, Routes } from 'react-router-dom'
import {
    Collapse, Nav, Navbar, NavbarBrand, NavbarText, NavbarToggler, NavItem, NavLink as BSNavLink
} from 'reactstrap'
import { ListView } from './bucket'
import * as Endpoint from './endpoint'
import { Redirector } from './navigation'
import { ListView as ListObjectView } from './object'
import { NotFound } from './page'
import * as Security from './security'

interface Props {

}

interface State {
    navigationIsOpen: boolean
}

class Layout extends React.PureComponent<Props, State> {
    protected security: Security.Store.Store
    protected endpoint: Endpoint.Store.Store
    constructor (props: Props) {
        super(props)

        this.state = {
            navigationIsOpen: false
        }

        this.security = getKernel().container.get('security') as Security.Store.Store
        this.endpoint = getKernel().container.get('endpoint') as Endpoint.Store.Store
    }

    render () {
        if (!this.security || !this.endpoint) {
            return null
        }

        if (!this.security.connected) {
            return <Security.LoginPage />
        }

        return (
            <Loader loadingInformation={this.endpoint.loader}>
                <Redirector />
                <div className="vh-100 d-flex flex-column">
                    <Navbar color="primary" dark expand="md">
                        <NavbarBrand tag="div" className="mr-0">
                            <Link to="/" className="text-white text-decoration-none">
                                <Icon size={1} path={mdiCloudSearchOutline} className="me-2" />
                                <FormattedMessage id="app.title" />
                            </Link>
                        </NavbarBrand>
                        <NavbarToggler onClick={this.toggle.bind(this)} />
                        <Collapse isOpen={this.state.navigationIsOpen} navbar className="w-100">
                            <Nav className="d-flex justify-content-end w-100" navbar>
                                <NavbarText>
                                    <Icon size={1} path={mdiAccount} className="me-2" />
                                    { this.security.informations.username }
                                </NavbarText>
                                <NavItem>
                                    <BSNavLink onClick={() => this.security.logout()}>
                                        <Icon size={1} path={mdiLogout} className="me-2" />
                                        <FormattedMessage id="app.logout" />
                                    </BSNavLink>
                                </NavItem>
                            </Nav>
                        </Collapse>
                    </Navbar>

                    <div className="flex-fill overflow-hidden">
                        <Routes>
                            <Route path="/" element={<Outlet/>}>
                                <Route path="/" element={<ListView/>} />
                                <Route path="/bucket/:name/*" element={<ListObjectView/>} />
                                <Route path="*" element={<NotFound/>} />
                            </Route>
                        </Routes>
                    </div>
                </div>
            </Loader>
        )
    }

    protected toggle () {
        this.setState({
            navigationIsOpen: !this.state.navigationIsOpen
        })
    }
}

export default observer(Layout)
