import { makeObservable, observable, action } from 'mobx'
import Store from '../store'
import { Store as EndpointStore } from '../../endpoint/store'
import { Store as SecurityStore } from '../../security/store'
import { ApiRequest, Response } from '@code-202/agent'
import { PrivateLoader } from '@code-202/loader'
import { Denormalizable, Normalizable } from '@code-202/serializer'
import { Informations } from '../entities'
import { PrivateLoaderNormalized } from '@code-202/loader/build/private-loader'

export default class Inspector implements Normalizable<InspectorNormalized>, Denormalizable<InspectorNormalized> {
    protected _store: Store
    protected _securityStore: SecurityStore
    protected _endpointStore: EndpointStore

    protected _request: ApiRequest
    protected _loader: PrivateLoader

    public informations: Informations | null = null
    public deleting: boolean = false
    public error: false | string = false

    constructor (store: Store, securityStore: SecurityStore, endpointStore: EndpointStore) {
        makeObservable(this, {
            informations: observable,
            deleting: observable,
            error: observable,

            loadInformations: action,
            resetInformations: action,
        })

        this._store = store
        this._securityStore = securityStore
        this._endpointStore = endpointStore

        this._request = new ApiRequest(this._securityStore.endpoint + '/buckets/{name}', 'GET')
        this._request.addAuthorizationService(this._securityStore)
        this._loader = new PrivateLoader(this._request)
    }

    get loader (): PrivateLoader {
        return this._loader
    }

    loadInformations () {
        if (!this._store.currentBucket) {
            return
        }

        if (this._request.status !== 'pending') {
            this._request
                .setUrlParam('name', this._store.currentBucket.name)
                .send()
                    .then(action((response: Response.Response) => {
                        if (this._store.currentBucket) {
                            const defaultInformations: Informations = {
                                name: this._store.currentBucket.name,
                                creationDate: this._store.currentBucket.creationDate,
                                acl: {
                                    publicPermission: 'PRIVATE'
                                },
                                cors: {
                                    rules: []
                                }
                            }

                            this.informations = Object.assign(defaultInformations, response.data as Informations)
                        }

                    })).catch(action((response: Response.Response) => {
                        this.error = 'app.buckets.load.error.bad_name'
                    }))
        }
    }

    resetInformations () {
        this.informations = null
    }

    get publicUrl (): string {
        return `${this._endpointStore.endpoint}/${this._store.currentBucket ? this._store.currentBucket.name : ''}`
    }

    public normalize(): InspectorNormalized {
        return {
            informations: this.informations,
            deleting: this.deleting,
            error: this.error,
            loader: this._loader.normalize(),
        }
    }

    public denormalize(data: InspectorNormalized): void {
        try {
            action(() => {
                this.informations = data.informations
                this.deleting = data.deleting
                this.error = data.error
                this._loader.denormalize(data.loader)
            })()
        } catch (e) {
            console.error('Impossible to deserialize : bad data')
        }
    }
}

export interface InspectorNormalized {
    informations: Informations | null
    deleting: boolean
    error: false | string
    loader: PrivateLoaderNormalized
}
