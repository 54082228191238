import { getKernel } from '@code-202/kernel'
import { LoaderIndicator } from '@code-202/loader'
import { mdiApproximatelyEqual, mdiArrowDown, mdiArrowUp, mdiFolderOutline, mdiMinus, mdiPlusThick, mdiRefresh, mdiUpload } from '@mdi/js'
import Icon from '@mdi/react'
import * as cn from 'classnames'
import { observer } from 'mobx-react'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { NavigateFunction } from 'react-router-dom'
import {
    Alert,
    Button,
    Input,
    Table
} from 'reactstrap'
import { withNavigate, withParams } from '../router'
import { S3Directory, S3File, S3Object } from './entities'
import ListFileView from './list-file-view'
import ModalCreateDirectory from './modal-create-directory'
import ModalDeleteObject from './modal-delete-object'
import PathLinks from './path-links'
import ShowObjectView from './show-object-view'
import Store from './store'
import Uploader from './uploader'

interface Props {
    navigate?: NavigateFunction
    params?: Record<string, string | undefined>
}

interface State {}

class ListView extends React.Component<Props, State> {
    protected uploadInput: React.RefObject<HTMLInputElement>
    protected objectStore: Store

    constructor(props: Props) {
        super(props)

        this.objectStore = getKernel().container.get('objectStore') as Store

        this.uploadInput = React.createRef<HTMLInputElement>()

        if (this.props.params) {
            this.objectStore.setCurrentPath(this.props.params.name, this.props.params['*'])
        }
    }

    componentDidMount () {
        if (this.props.params) {
            this.objectStore.setCurrentPath(this.props.params.name, this.props.params['*'])
        }
    }

    componentDidUpdate () {
        if (this.props.params) {
            this.objectStore.setCurrentPath(this.props.params.name, this.props.params['*'])
        }
    }

    render () {
        const { navigate } = this.props

        if (!navigate) {
            return null
        }

        const root = `/bucket/${this.objectStore.currentBucket}`
        const objects = this.objectStore.lister.list.contents
        return (
            <div className="h-100 d-flex flex-column">
                <div className="d-flex justify-content-between align-items-center p-2">
                    <div>
                        <PathLinks root={root} />
                    </div>
                    <div>
                        <Button
                            color="primary"
                            onClick={() => this.objectStore.refresh()}
                            className="me-2"
                            >
                            <Icon size={1} path={mdiRefresh} className="me-2" />
                            <FormattedMessage id="app.objects.refresh" />
                        </Button>
                        <Button
                            color="success"
                            onClick={() => this.objectStore.directoryCreator.toggleCreateDirectoryModal()}
                            className="me-2"
                            >
                            <Icon size={1} path={mdiPlusThick} className="me-2" />
                            <FormattedMessage id="app.objects.add.directory" />
                        </Button>
                        <Button
                            color="success"
                            onClick={() => this.uploadInput.current ? this.uploadInput.current.click() : null}
                            className="me-2"
                            >
                            <Icon size={1} path={mdiUpload} className="me-2" />
                            <FormattedMessage id="app.objects.add.files" />
                        </Button>
                        <Input
                            type="file"
                            className="d-none"
                            innerRef={this.uploadInput}
                            onChange={this.onUploadFileChangeHandler}
                            multiple
                            />
                        <Uploader />
                    </div>
                </div>
                <div className="d-flex flex-fill overflow-hidden">
                    <div className="w-50 overflow-auto flex-fill d-flex flex-column position-relative">
                        { this.objectStore.lister.list.isTruncated && (
                            <div className="alert alert-warning m-3 sticky-top">
                                <FormattedMessage id="app.objects.list.truncated"  values={{ limit: this.objectStore.lister.list.limit }}/>
                            </div>
                        )}

                        <div className="position-absolute" style={{ top: 10, right: 10 }}>
                            <LoaderIndicator loadingInformation={this.objectStore.lister.listLoader} />
                        </div>

                        <Table className="noselect">
                            <thead>
                                <tr>
                                    <th>
                                        <FormattedMessage id="app.objects.list.name" />
                                        <Button
                                            color="primary"
                                            outline
                                            size="sm"
                                            className="border-0 p-0 ms-2"
                                            onClick={() => this.objectStore.lister.setOrder(this.objectStore.lister.order === 'nameAsc' ? 'nameDesc' : 'nameAsc')}
                                            >
                                            <Icon path={
                                                this.objectStore.lister.order === 'nameAsc' ? mdiArrowUp :
                                                this.objectStore.lister.order === 'nameDesc' ? mdiArrowDown :
                                                this.objectStore.lister.order.substr(0, 4) !== 'name' ? mdiApproximatelyEqual :
                                                mdiMinus
                                            } size={1}/>
                                        </Button>
                                    </th>
                                    <th>
                                        <FormattedMessage id="app.objects.list.lastModified" />
                                        <Button
                                            color="primary"
                                            outline
                                            size="sm"
                                            className="border-0 p-0 ms-2"
                                            onClick={() => this.objectStore.lister.setOrder(this.objectStore.lister.order === 'lastModifiedAsc' ? 'lastModifiedDesc' : 'lastModifiedAsc')}
                                            >
                                            <Icon path={
                                                this.objectStore.lister.order === 'lastModifiedAsc' ? mdiArrowUp :
                                                this.objectStore.lister.order === 'lastModifiedDesc' ? mdiArrowDown :
                                                this.objectStore.lister.order.substr(0, 12) !== 'lastModified' ? mdiApproximatelyEqual :
                                                mdiMinus
                                            } size={1}/>
                                        </Button>
                                    </th>
                                    <th>
                                        <FormattedMessage id="app.objects.list.size" />
                                        <Button
                                            color="primary"
                                            outline
                                            size="sm"
                                            className="border-0 p-0 ms-2"
                                            onClick={() => this.objectStore.lister.setOrder(this.objectStore.lister.order === 'sizeAsc' ? 'sizeDesc' : 'sizeAsc')}
                                            >
                                            <Icon path={
                                                this.objectStore.lister.order === 'sizeAsc' ? mdiArrowUp :
                                                this.objectStore.lister.order === 'sizeDesc' ? mdiArrowDown :
                                                this.objectStore.lister.order.substr(0, 4) !== 'size' ? mdiApproximatelyEqual :
                                                mdiMinus
                                            } size={1}/>
                                        </Button>
                                    </th>
                                </tr>
                            </thead>
                            { objects.length > 0 && (
                                <tbody>
                                    { objects.map((object: S3Object, index: number) => {
                                        if (object.type === 'dir') {
                                            const directory = object as S3Directory
                                            return (
                                                <tr key={index}
                                                    onDoubleClick={() => navigate(`${root}/${directory.key}`)}
                                                    onClick={() => this.objectStore.toggleCurrentObject(directory)}
                                                    className={cn({'table-primary': this.objectStore.currentObjects.indexOf(directory) >= 0 })}
                                                    >
                                                    <td>
                                                        <Icon size={1} path={mdiFolderOutline} className="me-2" />
                                                        { directory.name }
                                                    </td>
                                                    <td>
                                                        -
                                                    </td>
                                                    <td>
                                                        -
                                                    </td>
                                                </tr>
                                            )
                                        }

                                        return (
                                            <ListFileView key={index} file={object as S3File} />
                                        )
                                    })}
                                </tbody>
                            )}
                        </Table>
                        { this.objectStore.lister.listLoader.status === 'done' && objects.length === 0 && (
                            <div className="h-100 d-flex justify-content-center align-items-center p-5">
                                <Alert color="warning">
                                    <FormattedMessage id="app.objects.list.empty" />
                                </Alert>
                            </div>
                        )}
                    </div>
                    <div className="w-50 flex-fill d-flex justify-content-center align-items-center border-start">
                        <ShowObjectView />
                    </div>
                </div>
                <ModalCreateDirectory />
                <ModalDeleteObject />
            </div>
        )
    }

    onUploadFileChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.objectStore.objectUploader.upload(event.target.files)

        if (this.uploadInput.current) {
            this.uploadInput.current.value = ''
        }
    }
}

export default withParams(withNavigate(observer(ListView)))
