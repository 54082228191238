import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { observer } from 'mobx-react'
import Store from './store'
import {
    Alert,
    Button,
    ListGroup, ListGroupItem,
    Row, Col
} from 'reactstrap'
import BucketPermissionView from './bucket-permission-view'
import BucketCorsView from './bucket-cors-view'
import { getKernel } from '@code-202/kernel'
import { mdiOpenInNew, mdiTrashCanOutline } from '@mdi/js'
import Icon from '@mdi/react'

interface Props {}

interface State {}

class ShowBucketView extends React.Component<Props, State> {

    protected bucketStore: Store

    constructor(props: Props) {
        super(props)

        this.bucketStore = getKernel().container.get('bucketStore') as Store
    }

    render () {
        if (this.bucketStore.currentBucket === null) {
            return (
                <Alert color="info">
                    <FormattedMessage id="app.buckets.show.current.empty" />
                </Alert>
            )
        }

        return (
            <div className="d-flex flex-column w-100 h-100">
                <ListGroup className="list-group-striped">
                    <ListGroupItem className="container">
                        <Row>
                            <Col sm={3}>
                                <FormattedMessage id="app.buckets.show.name" />
                            </Col>
                            <Col>
                                { this.bucketStore.currentBucket.name }
                            </Col>
                            <Col sm={2}>
                                <Button
                                    color="danger"
                                    outline
                                    className="border-0 p-0 mx-2"
                                    onClick={() => this.bucketStore.deleter.startDeleting()}
                                >
                                    <Icon size={1} path={mdiTrashCanOutline} />
                                </Button>
                            </Col>
                        </Row>
                    </ListGroupItem>
                    <ListGroupItem className="container">
                        <Row>
                            <Col sm={3}>
                                <FormattedMessage id="app.buckets.show.permission" />
                            </Col>
                            <Col>
                                <BucketPermissionView informations={this.bucketStore.inspector.informations} />
                            </Col>
                        </Row>
                    </ListGroupItem>
                    { this.bucketStore.inspector.informations && this.bucketStore.inspector.informations.acl.publicPermission !== 'PRIVATE' && (
                        <ListGroupItem className="container">
                            <Row>
                                <Col sm={3}>
                                    <FormattedMessage id="app.objects.show.file.link" />
                                </Col>
                                <Col>
                                    <a
                                            href={this.bucketStore.inspector.publicUrl}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            >
                                            <Icon size={1} path={mdiOpenInNew} />
                                        </a>
                                </Col>
                            </Row>
                        </ListGroupItem>
                    )}
                    { this.bucketStore.inspector.informations && (
                        <ListGroupItem className="container">
                            <Row>
                                <Col sm={3}>
                                    <FormattedMessage id="app.buckets.show.cors" />
                                </Col>
                                <Col>
                                     <BucketCorsView cors={this.bucketStore.inspector.informations.cors} />
                                </Col>
                            </Row>
                        </ListGroupItem>
                    )}
                </ListGroup>
            </div>
        )
    }
}

export default observer(ShowBucketView)
