import * as React from 'react'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import { observer } from 'mobx-react'
import Store from './store'
import {
    Button,
    Form,
    Input, InputGroup
} from 'reactstrap'
import { S3FileAdvanced, S3ObjectPublicPermission } from './entities'
import * as cn from 'classnames'
import { mdiCheck, mdiClose, mdiLoading, mdiPencil } from '@mdi/js'
import Icon from '@mdi/react'
import { getKernel } from '@code-202/kernel'

interface Props extends WrappedComponentProps<'intl'> {
    file: S3FileAdvanced
}

interface State {}

class FilePermissionView extends React.Component<Props, State> {

    protected objectStore: Store

    constructor(props: Props) {
        super(props)

        this.objectStore = getKernel().container.get('objectStore') as Store
    }

    render () {
        const { file, intl } = this.props

        if (this.objectStore.objectPermissionUpdater.editObject === null) {
            return (
                <>
                    <FormattedMessage id={`app.objects.permission.values.${file.publicPermission}`} />
                    <Button
                        color="primary"
                        outline
                        className="border-0 p-0 mx-2"
                        onClick={() => this.objectStore.objectPermissionUpdater.setEditObject(file)}
                    >
                        <Icon size={1} path={mdiPencil} />
                    </Button>
                </>
            )
        }

        if (this.objectStore.objectPermissionUpdater.status === 'pending') {
            return (
                <Icon size={1} path={mdiLoading} spin={true} />
            )
        }

        if (this.objectStore.objectPermissionUpdater.nbUpdate > 0) {
            return <>
                <FormattedMessage id="app.objects.permission.success" values={{ nb: this.objectStore.objectPermissionUpdater.nbUpdate }}/>
                <Button
                    color="primary"
                    size="sm"
                    onClick={() => this.objectStore.objectPermissionUpdater.toPlainMode()}
                    className="ms-2"
                >
                    <FormattedMessage id="app.objects.permission.clear" />
                </Button>
            </>
        }

        return (
            <Form onSubmit={this.onSubmitCreateHandler}>
                <InputGroup size="sm" className={cn({'is-invalid': this.objectStore.objectPermissionUpdater.status === 'error'})}>
                    <Input
                        type="select"
                        value={this.objectStore.objectPermissionUpdater.newPermission}
                        onChange={this.onNewPermissionChangeHandler}
                        >
                        <option value="PRIVATE">
                            { intl.formatMessage({ id: 'app.objects.permission.values.PRIVATE' }) }
                        </option>
                        <option value="READ">
                            { intl.formatMessage({ id: 'app.objects.permission.values.READ' }) }
                        </option>
                        <option value="WRITE">
                            { intl.formatMessage({ id: 'app.objects.permission.values.WRITE' }) }
                        </option>
                    </Input>
                    <Button
                        color="warning"
                        onClick={() => this.objectStore.objectPermissionUpdater.toPlainMode()}
                        >
                        <Icon size={1} path={mdiClose} />
                    </Button>
                    <Button
                        color="success"
                        type="submit"
                        >
                        <Icon size={1} path={mdiCheck} />
                    </Button>
                </InputGroup>
            </Form>
        )
    }

    onNewPermissionChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.objectStore.objectPermissionUpdater.setNewPermission(event.target.value as S3ObjectPublicPermission)
    }

    onSubmitCreateHandler = (event: React.FormEvent) => {
        event.preventDefault()

        this.objectStore.objectPermissionUpdater.update()
    }
}

export default injectIntl(observer(FilePermissionView))
