import * as React from 'react'
import { observer } from 'mobx-react'
import Store from './store'
import { Link } from 'react-router-dom'
import { mdiChevronRight, mdiFolderHome } from '@mdi/js'
import Icon from '@mdi/react'
import { getKernel } from '@code-202/kernel'


interface Props {
    root: string
}

interface State {}

class PathLinks extends React.Component<Props, State> {

    protected objectStore: Store

    constructor(props: Props) {
        super(props)

        this.objectStore = getKernel().container.get('objectStore') as Store
    }

    render () {
        const { root } = this.props

        const paths = this.objectStore.currentPath ? this.objectStore.currentPath.split('/') : []

        return (
            <>
                <Link to={root}>
                    <Icon size={1} path={mdiFolderHome} className="me-2" />
                    { this.objectStore.currentBucket }
                </Link>
                { paths.map((path: string, index: number)=> (
                    <span key={index}>
                        <Icon size={1} path={mdiChevronRight} className="mx-3" />
                        <Link to={root + '/' + paths.slice(0, index + 1).join('/')}>
                            { path }
                        </Link>
                    </span>
                ))}
            </>
        )
    }
}

export default observer(PathLinks)
