import { makeObservable, observable, action, computed, when } from 'mobx'
import { Denormalizable, Normalizable } from '@code-202/serializer'
import Store from '../store'
import { Store as EndpointStore, Loader } from '../../endpoint'
import { S3List, S3FileType, S3Object, S3File, S3Directory } from '../entities'
import { ListObjectsCommand, ListObjectsCommandOutput } from '@aws-sdk/client-s3'
import { Agent } from '@code-202/agent'
import { ClientLoaderNormalized } from '../../endpoint/loader'

export type ListOrder = 'nameAsc' | 'nameDesc' | 'lastModifiedAsc' | 'lastModifiedDesc' | 'sizeAsc' | 'sizeDesc'

export default class Lister implements Normalizable<ListerNormalized>, Denormalizable<ListerNormalized> {
    protected _store: Store
    protected _endpointStore: EndpointStore.Store
    protected _listLoader: Loader.ClientLoader
    public list: S3List
    public order: ListOrder = 'nameAsc'

    constructor (store: Store, endpointStore: EndpointStore.Store) {
        makeObservable(this, {
            list: observable,
            order: observable,

            setOrder: action,
        })

        this._store = store
        this._endpointStore = endpointStore

        this.list = {
            contents: [],
            isTruncated: false,
            limit: 0
        }

        this._listLoader = new Loader.ClientLoader()

        when (
            () => this._endpointStore.ready,
            () => this.refresh()
        )
    }

    get listLoader (): Loader.ClientLoader {
        return this._listLoader
    }

    refresh () {
        if (!this._store.currentBucket || !this._endpointStore.client) {
            return
        }

        const command = new ListObjectsCommand({
            Bucket: this._store.currentBucket,
            Delimiter: '/',
            Prefix: this._store.currentPath ? this._store.currentPath + '/' : ''
        })

        const promise = this._endpointStore.client.send(command).then(action((data: ListObjectsCommandOutput) => {
            this.list.contents.splice(0)
            this.list.isTruncated = data.IsTruncated ? data.IsTruncated : false
            this.list.limit = data.MaxKeys ? data.MaxKeys : 0

            if (data.Contents !== undefined) {
                for (const res of data.Contents) {
                    const name: string = res.Key ? res.Key.replace(new RegExp('^(.*/)?([^/]+)$'), '$2') : ''
                    const path: string = res.Key ? res.Key.replace(new RegExp('^(.*/)?([^/]+)$'), '$1') : ''

                    this.list.contents.push({
                        name: name,
                        path: path,
                        key: res.Key,
                        lastModified: res.LastModified ? res.LastModified : new Date(),
                        etag: res.ETag,
                        size: res.Size ? res.Size : 0,
                        type: this.determineFileType(res.Key ? res.Key : '')
                    } as S3File)
                }
            }

            if (data.CommonPrefixes !== undefined) {
                for (const res of data.CommonPrefixes) {
                    const name: string = res.Prefix ? res.Prefix.replace(new RegExp('^(.*/)?([^/]+)/$'), '$2') : ''
                    const path: string = res.Prefix ? res.Prefix.replace(new RegExp('^(.*/)?([^/]+)/$'), '$1') : ''

                    this.list.contents.push({
                        key: res.Prefix,
                        name: name,
                        path: path,
                        type: 'dir'
                    } as S3Directory)
                }
            }

            this.sort()

            // Refresh selected objects
            if (this._store.currentObjects.length > 0) {
                const list: S3Object[] = []
                for (const object of this._store.currentObjects) {
                    for (const newObject of this.list.contents) {
                        if (object.name === newObject.name && object.path === newObject.path && object.type === newObject.type) {
                            list.push(newObject)
                        }
                    }
                }
                this._store.setCurrentObjects(list)
            }
        }))
        .catch(action((err) => {
            this.list.contents.splice(0)

            return err
        }))

        this._listLoader.setPromise(promise)
        Agent.watchPromise(promise)
    }

    getObjects (from?: S3Object, to?: S3Object): S3Object[] {
        if (this.list.contents.length === 0) {
            return []
        }

        const indexFrom = from === undefined ? 0 : this.list.contents.indexOf(from)
        if (indexFrom < 0) {
            return []
        }
        let indexTo = to === undefined ? this.list.contents.length - 1 : this.list.contents.indexOf(to)
        if (indexTo < 0) {
            indexTo = this.list.contents.length - 1
        }

        const offset = Math.max(0, Math.min(indexFrom, indexTo))
        const limit = Math.min(Math.abs(indexTo - indexFrom) + 1)

        return this.list.contents.slice(offset, offset + limit)
    }

    setOrder (order: ListOrder) {
        this.order = order

        this.sort()
    }

    protected sort () {
        const list = this.list.contents.splice(0)

        list.sort((o1: S3Object, o2: S3Object) => {
            let v1: string | number = ''
            let v2: string | number = ''
            let reverse = false
            switch (this.order) {
            case 'nameDesc':
                reverse = true
                // falls through
            case 'nameAsc':
                v1 = o1.name
                v2 = o2.name
                break
            case 'lastModifiedDesc':
                reverse = true
                // falls through
            case 'lastModifiedAsc':
                v1 = o1.type === 'dir' ? '' : (o1 as S3File).lastModified.getTime()
                v2 = o2.type === 'dir' ? '' : (o2 as S3File).lastModified.getTime()
                break
            case 'sizeDesc':
                reverse = true
                // falls through
            case 'sizeAsc':
                v1 = o1.type === 'dir' ? '' : (o1 as S3File).size
                v2 = o2.type === 'dir' ? '' : (o2 as S3File).size
                break
            }

            if (v1 === v2) {
                return 0
            }

            const up = reverse ? -1 : 1
            const down = reverse ? 1 : -1
            return v1 > v2 ? up : down
        })

        for (const object of list) {
            if (object.type === 'dir') {
                this.list.contents.push(object)
            }
        }

        for (const object of list) {
            if (object.type !== 'dir') {
                this.list.contents.push(object)
            }
        }
    }

    public determineFileType (key: string): S3FileType {
        let type: S3FileType = 'file'
        const reg = /(?:\.([^.]+))?$/
        const parts = reg.exec(key.toLowerCase())
        if (parts && parts[1] !== undefined) {
            switch (parts[1]) {
                case 'jpg':
                case 'png':
                case 'jpeg':
                case 'gif':
                    type = 'img'
                    break
                case 'pdf':
                    type = 'pdf'
                    break
            }
        }

        return type
    }

    public normalize(): ListerNormalized {
        return {
            list: this.list,
            order: this.order,
            listLoader: this._listLoader.normalize(),
        }
    }

    public denormalize(data: ListerNormalized): void {
        try {
            data.list.contents.map((file) => {
                file.lastModified = new Date(file.lastModified)
            })
            action(() => {
                this.list = data.list
                this.order = data.order
                this._listLoader.denormalize(data.listLoader)
            })()
        } catch (e) {
            console.error('Impossible to deserialize : bad data', e)
        }
    }
}

export interface ListerNormalized {
    list: S3List
    order: ListOrder
    listLoader: ClientLoaderNormalized
}
