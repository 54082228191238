import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import Store from './store'
import { LoaderIndicator } from '@code-202/loader'
import {
    Alert,
    Button,
    Form, FormGroup, Label,
    Input, InputGroup
} from 'reactstrap'
import { CORSRule, CORSRuleMethod } from './entities'
import { getKernel } from '@code-202/kernel'
import { mdiClose, mdiPlus } from '@mdi/js'
import Icon from '@mdi/react'

interface Props {
    index: number
}

interface State {
    rule: CORSRule
}

class FormEditCorsRule extends React.Component<Props, State> {

    protected bucketStore: Store

    constructor (props: Props) {
        super(props)

        this.bucketStore = getKernel().container.get('bucketStore') as Store

        let rule: CORSRule = {
                AllowedHeaders: [],
                AllowedOrigins: ['*'],
                AllowedMethods: ['GET'],
                MaxAgeSeconds: 3600
            }

        if (this.bucketStore.inspector.informations && this.props.index >= 0 && this.props.index < this.bucketStore.inspector.informations.cors.rules.length) {
            rule = toJS(this.bucketStore.inspector.informations.cors.rules[this.props.index])
        }

        this.state = {
            rule: rule
        }
    }

    render () {
        if (!this.bucketStore.inspector.informations) {
            return null
        }

        const methods: CORSRuleMethod[] = ['GET', 'PUT', 'HEAD', 'POST', 'DELETE']

        return (
            <Form onSubmit={(this.onSubmitEditHandler)}>
                <FormGroup>
                    <Label>
                        <FormattedMessage id="app.buckets.show.cors.allowed-headers" />
                    </Label>
                    { this.state.rule.AllowedHeaders.map((header: string, i: number) => (
                        <InputGroup key={i} className="mb-2">
                            <Input
                                value={header}
                                required
                                onChange={this.onChangeAllowedHeadersHandler.bind(this, i)} />

                            <Button
                                outline
                                color="danger"
                                onClick={this.onRemoveAllowedHeadersHandler.bind(this, i)}
                                >
                                <Icon size={1} path={mdiClose} />
                            </Button>
                        </InputGroup>
                    ))}
                    <Button
                        color="success"
                        outline
                        className="border-0 p-0 me-2"
                        onClick={this.onAddAllowedHeaderHandler}
                    >
                        <Icon size={1} path={mdiPlus} />
                    </Button>
                </FormGroup>

                <FormGroup>
                    <Label>
                        <FormattedMessage id="app.buckets.show.cors.allowed-methods" />
                    </Label>
                    <div>
                        { methods.map((method: CORSRuleMethod, i: number) => (
                            <div
                                key={i}
                                className="form-check form-check-inline">
                                <Input
                                    type="checkbox"
                                    checked={this.state.rule.AllowedMethods.indexOf(method) >= 0}
                                    id={`method_${method}`}
                                    onChange={this.onChangeAllowedMethodsHandler.bind(this, method)}
                                    />
                                <Label check for={`method_${method}`}>
                                    <FormattedMessage id={`app.buckets.show.cors.allowed-methods.values.${method}`} />
                                </Label>
                            </div>
                        ))}
                    </div>
                </FormGroup>

                <FormGroup>
                    <Label>
                        <FormattedMessage id="app.buckets.show.cors.allowed-origins" />
                    </Label>
                    { this.state.rule.AllowedOrigins.map((header: string, i: number) => (
                        <InputGroup key={i} className="mb-2">
                            <Input
                                value={header}
                                required
                                onChange={this.onChangeAllowedOriginsHandler.bind(this, i)} />

                            { (i > 0 || this.state.rule.AllowedOrigins.length > 1) && (
                                <Button
                                    outline
                                    color="danger"
                                    onClick={this.onRemoveAllowedOriginsHandler.bind(this, i)}
                                    >
                                    <Icon size={1} path={mdiClose} />
                                </Button>
                            )}
                        </InputGroup>
                    ))}
                    <Button
                        color="success"
                        outline
                        className="border-0 p-0 me-2"
                        onClick={this.onAddAllowedOriginHandler}
                    >
                        <Icon size={1} path={mdiPlus} />
                    </Button>
                </FormGroup>

                <FormGroup>
                    <Label>
                        <FormattedMessage id="app.buckets.show.cors.max-age-seconds" />
                    </Label>
                    <Input
                        value={this.state.rule.MaxAgeSeconds}
                        className="mb-2"
                        onChange={this.onChangeMaxAgeSecondHandler} />
                </FormGroup>

                { this.bucketStore.corsUpdater.error && (
                    <Alert color="danger">
                        <FormattedMessage id={this.bucketStore.corsUpdater.error} />
                    </Alert>
                )}

                <div className="d-flex justify-content-end align-items-center">

                    { this.bucketStore.corsUpdater.loader.status !== 'waiting' && (
                        <div className="me-2">
                            <LoaderIndicator loadingInformation={this.bucketStore.corsUpdater.loader} />
                        </div>
                    )}

                    <Button
                        type="submit"
                        color="success"
                        disabled={this.bucketStore.corsUpdater.loader.status === 'pending'}
                        >
                        <FormattedMessage id="app.buckets.show.cors.edit.confirm" />
                    </Button>
                </div>
            </Form>
        )
    }

    onAddAllowedHeaderHandler = () => {
        const s: State = Object.assign({}, this.state)

        s.rule.AllowedHeaders.push('')

        this.setState(s)
    }

    onChangeAllowedHeadersHandler (index: number, event: React.ChangeEvent<HTMLInputElement>) {

        const s: State = Object.assign({}, this.state)

        s.rule.AllowedHeaders[index] = event.target.value

        this.setState(s)
    }

    onRemoveAllowedHeadersHandler (index: number) {

        const s: State = Object.assign({}, this.state)

        s.rule.AllowedHeaders.splice(index, 1)

        this.setState(s)
    }

    onChangeAllowedMethodsHandler (method: CORSRuleMethod, event: React.ChangeEvent<HTMLInputElement>) {

        const s: State = Object.assign({}, this.state)

        const methods: CORSRuleMethod[] = ['GET', 'PUT', 'HEAD', 'POST', 'DELETE']

        const tmp = []
        for (const m of methods) {
            const presence = this.state.rule.AllowedMethods.indexOf(m) >= 0
            if (method === m) {
                if (event.target.checked) {
                    tmp.push(m)
                }
            } else {
                if (this.state.rule.AllowedMethods.indexOf(m) >= 0) {
                    tmp.push(m)
                }
            }
        }

        s.rule.AllowedMethods = tmp as CORSRuleMethod[]

        this.setState(s)
    }

    onAddAllowedOriginHandler = () => {
        const s: State = Object.assign({}, this.state)

        s.rule.AllowedOrigins.push('')

        this.setState(s)
    }

    onChangeAllowedOriginsHandler (index: number, event: React.ChangeEvent<HTMLInputElement>) {

        const s: State = Object.assign({}, this.state)

        s.rule.AllowedOrigins[index] = event.target.value

        this.setState(s)
    }

    onRemoveAllowedOriginsHandler (index: number) {

        const s: State = Object.assign({}, this.state)

        s.rule.AllowedOrigins.splice(index, 1)

        this.setState(s)
    }

    onChangeMaxAgeSecondHandler = (event: React.ChangeEvent<HTMLInputElement>) => {

        const n: number = parseInt(event.target.value)

        const s: State = Object.assign({}, this.state)

        s.rule.MaxAgeSeconds = isNaN(n) ? 0 : n

        this.setState(s)
    }

    onSubmitEditHandler = (event: React.FormEvent) => {
        event.preventDefault()

        if (this.bucketStore) {
            this.bucketStore.corsUpdater.update(this.state.rule)
        }
    }
}

export default observer(FormEditCorsRule)
