import { getKernel } from '@code-202/kernel';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { NavigateFunction } from 'react-router-dom'
import { Navigator } from '../navigation'
import { withNavigate } from '../router'

interface Props extends RouteComponentProps<any> {
    navigate?: NavigateFunction
}

class Redirector extends React.Component<Props, object> {

    protected navigator: Navigator

    constructor (props: Props) {
        super(props)

        this.navigator = getKernel().container.get('navigator') as Navigator

        this.navigator.on('redirect', this.onRedirect)
        this.navigator.on('goBack', this.onGoBack)
    }

    componentWillUnmount () {
        this.navigator.off('redirect', this.onRedirect)
        this.navigator.off('goBack', this.onGoBack)
    }

    onRedirect = (path: string, exact: boolean = true) => {
        console.log('redirect')
        if (this.props.navigate) {
            this.props.navigate(path)
        }
    }

    onGoBack = (path: string, exact: boolean = true) => {
        console.log('goback')
        if (this.props.navigate) {
            this.props.navigate(-1)
        }
    }

    render() {
        return <></>
    }
}

export default withNavigate(Redirector)
