import { LoaderRequest } from '@code-202/loader'
import { Denormalizable, Normalizable } from '@code-202/serializer'
import { makeObservable, observable, action } from 'mobx'

export class ClientLoader implements LoaderRequest.Informations, Normalizable<ClientLoaderNormalized>, Denormalizable<ClientLoaderNormalized> {

    public status: LoaderRequest.Status = 'waiting'
    public progress: number = 0
    public uploadProgress: number = 0
    public errors: string[] = []

    constructor () {
        makeObservable(this, {
            status: observable,
            progress: observable,
            uploadProgress: observable,
            errors: observable,

            setPromise: action,
            setProgress: action,
        })
    }

    setPromise (promise: Promise<any>) {
        this.status = 'pending'
        this.progress = 0
        this.uploadProgress = 0
        this.errors.splice(0)

        promise.then(action(() => {
            this.status = 'done'
        })).catch(action((err) => {
            this.status = 'error'
            this.errors.push(err.message)
        }))
    }

    setProgress (progress: Progress) {
        this.uploadProgress = Math.ceil(100 * progress.loaded / progress.total)
    }

    public normalize(): ClientLoaderNormalized {
        return {
            status: this.status,
            progress: this.progress,
            uploadProgress: this.uploadProgress,
            errors: this.errors,
        }
    }

    public denormalize(data: ClientLoaderNormalized): void {
        try {
            action(() => {
                this.status = data.status
                this.progress = data.progress
                this.uploadProgress = data.uploadProgress
                this.errors = data.errors
            })()
        } catch (e) {
            console.error('Impossible to deserialize : bad data')
        }
    }
}

export interface Progress {
    loaded: number;
    total: number;
}

export interface ClientLoaderNormalized {
    status: LoaderRequest.Status
    progress: number
    uploadProgress: number
    errors: string[]
}
