import { makeObservable, observable, action } from 'mobx'
import Store from '../store'
import { Store as SecurityStore } from '../../security/store'
import { ApiRequest, Response, Request } from '@code-202/agent'
import { PrivateLoader } from '@code-202/loader'
import { Denormalizable, Normalizable } from '@code-202/serializer'
import { Bucket } from '../entities'
import { PrivateLoaderNormalized } from '@code-202/loader/build/private-loader'

export default class Deleter implements Normalizable<DeleterNormalized>, Denormalizable<DeleterNormalized> {
    protected _store: Store
    protected _securityStore: SecurityStore

    protected _request: ApiRequest
    protected _loader: PrivateLoader

    public deleting: boolean = false
    public error: false | string = false

    constructor (store: Store, securityStore: SecurityStore) {
        makeObservable(this, {
            deleting: observable,
            error: observable,

            startDeleting: action,
            stopDeleting: action,
            delete: action,
        })

        this._store = store
        this._securityStore = securityStore

        this._request = new ApiRequest(this._securityStore.endpoint + '/buckets/{name}', 'DELETE')
        this._request.addAuthorizationService(this._securityStore)
        this._loader = new PrivateLoader(this._request)
    }

    get loader (): PrivateLoader {
        return this._loader
    }

    startDeleting () {
        this.deleting = true
    }

    stopDeleting () {
        this.deleting = false
        this.error = false
    }

    delete () {
        if (!this.deleting) {
            this.startDeleting()
            return
        }

        if (!this._store.currentBucket) {
            return
        }

        if (this._request.status !== 'pending') {
            this._request
                .setUrlParam('name', this._store.currentBucket.name)
                .send()
                    .then(action(() => {
                        this.stopDeleting()
                        this._store.setCurrentObject(null)
                        this._store.refresh()
                    })).catch(action((response: Response.Response) => {
                        this.error = 'app.buckets.delete.error.not_empty'
                    }))
        }
    }

    public normalize(): DeleterNormalized {
        return {
            deleting: this.deleting,
            error: this.error,
            loader: this._loader.normalize(),
        }
    }

    public denormalize(data: DeleterNormalized): void {
        try {
            action(() => {
                this.deleting = data.deleting
                this.error = data.error
                this._loader.denormalize(data.loader)
            })()
        } catch (e) {
            console.error('Impossible to deserialize : bad data')
        }
    }
}

export interface DeleterNormalized {
    deleting: boolean
    error: false | string
    loader: PrivateLoaderNormalized
}
