import { makeObservable, observable, action } from 'mobx'
import Store from '../store'
import { Store as SecurityStore } from '../../security/store'
import { ApiRequest, Response } from '@code-202/agent'
import { PrivateLoader } from '@code-202/loader'
import { Denormalizable, Normalizable } from '@code-202/serializer'
import { Bucket } from '../entities'
import { PrivateLoaderNormalized } from '@code-202/loader/build/private-loader'

export default class Lister implements Normalizable<ListerNormalized>, Denormalizable<ListerNormalized> {
    protected _store: Store
    protected _securityStore: SecurityStore

    protected _request: ApiRequest
    protected _loader: PrivateLoader

    public buckets: Bucket[] = []

    constructor (store: Store, securityStore: SecurityStore) {
        makeObservable(this, {
            buckets: observable,
        })

        this._store = store
        this._securityStore = securityStore

        this._request = new ApiRequest(this._securityStore.endpoint + '/buckets', 'GET')
        this._request.addAuthorizationService(this._securityStore)
        this._loader = new PrivateLoader(this._request)
    }

    get loader (): PrivateLoader {
        return this._loader
    }

    refresh () {
        if (this._request.status !== 'pending') {
            this._request.send()
                .then(action((response: Response.Response) => {
                    this.buckets.splice(0)

                    for (const res of response.data) {
                        this.buckets.push({
                            name: res.Name,
                            creationDate: new Date(res.CreationDate)
                        })
                    }
                }))
                .catch(action((err) => {
                    this.buckets.splice(0)
                }))
        }
    }

    public normalize(): ListerNormalized {
        return {
            buckets: this.buckets,
            loader: this._loader.normalize(),
        }
    }

    public denormalize(data: ListerNormalized): void {
        try {
            action(() => {
                this.buckets.splice(0)
                for (const b of data.buckets) {
                    this.buckets.push(b)
                }
                this._loader.denormalize(data.loader)
            })()
        } catch (e) {
            console.error('Impossible to deserialize : bad data')
        }
    }
}

export interface ListerNormalized {
    buckets: Bucket[]
    loader: PrivateLoaderNormalized
}
