import * as React from 'react'
import { useParams } from 'react-router-dom'

const withParams = (Component) => {
    const Wrapper = (props) => {
        const params = useParams()

        return (
            <Component
                params={params}
                {...props}
                />
        )
    }

    return Wrapper
}

export default withParams
