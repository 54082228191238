import { makeObservable, observable, action } from 'mobx'
import Store from '../store'
import { Store as SecurityStore } from '../../security/store'
import { ApiRequest, Response, Request } from '@code-202/agent'
import { PrivateLoader } from '@code-202/loader'
import { Denormalizable, Normalizable } from '@code-202/serializer'
import { Bucket } from '../entities'
import { PrivateLoaderNormalized } from '@code-202/loader/build/private-loader'

export default class Creator implements Normalizable<CreatorNormalized>, Denormalizable<CreatorNormalized> {
    protected _store: Store
    protected _securityStore: SecurityStore

    protected _request: ApiRequest
    protected _loader: PrivateLoader

    public creating: boolean = false
    public error: false | string = false
    public newBucketName: string = ''

    constructor (store: Store, securityStore: SecurityStore) {
        makeObservable(this, {
            creating: observable,
            error: observable,
            newBucketName: observable,

            startCreating: action,
            stopCreating: action,
            setNewBucketName: action,
            create: action,
        })

        this._store = store
        this._securityStore = securityStore

        this._request = new ApiRequest(this._securityStore.endpoint + '/buckets', 'POST')
        this._request.addAuthorizationService(this._securityStore)
        this._loader = new PrivateLoader(this._request)
    }

    get loader (): PrivateLoader {
        return this._loader
    }

    startCreating () {
        this.creating = true
        this.newBucketName = ''
    }

    stopCreating () {
        this.creating = false
        this.newBucketName = ''
        this.error = false
    }

    setNewBucketName (name: string) {
        this.newBucketName = name
    }

    create () {
        if (this.newBucketName.length === 0) {
            this.error = 'app.buckets.add.error.name_empty'
        } else {
            this.error = false

            if (this._request.status !== 'pending') {
                this._request
                    .send({name: this.newBucketName})
                        .then(action(() => {
                            this.stopCreating()

                            this._store.refresh()
                        })).catch(action((response: Response.Response) => {
                            console.log('error', response)
                            this.error = 'app.buckets.add.error.bad_name'
                        }))
            }
        }
    }

    public normalize(): CreatorNormalized {
        return {
            creating: this.creating,
            error: this.error,
            newBucketName: this.newBucketName,
            loader: this._loader.normalize(),
        }
    }

    public denormalize(data: CreatorNormalized): void {
        try {
            action(() => {
                this.creating = data.creating
                this.error = data.error
                this.newBucketName = data.newBucketName
                this._loader.denormalize(data.loader)
            })()
        } catch (e) {
            console.error('Impossible to deserialize : bad data')
        }
    }
}

export interface CreatorNormalized {
    creating: boolean
    error: false | string
    newBucketName: string
    loader: PrivateLoaderNormalized
}
