import { makeObservable, observable, action, computed } from 'mobx'
import Store from '../store'
import { Store as EndpointStore, Loader } from '../../endpoint'
import { S3Object } from '../entities'
import { PutObjectCommand, PutObjectCommandOutput } from '@aws-sdk/client-s3'
import { Upload } from '@aws-sdk/lib-storage'

export default class ObjectUploader {
    protected _store: Store
    protected _endpointStore: EndpointStore.Store
    protected _abortControllers: AbortController[] = []

    public uploaderIsOpen: boolean = false
    public uploadings: Uploading[] = []

    constructor (store: Store, endpointStore: EndpointStore.Store) {
        makeObservable(this, {
            uploaderIsOpen: observable,
            uploadings: observable,

            upload: action,
            toggleUploader: action,
            remove: action,

            status: computed,
        })

        this._store = store
        this._endpointStore = endpointStore
    }

    upload (files: FileList | null) {
        if (!this._endpointStore.client) {
            return
        }

        if (files && this._store.currentBucket) {
            for (const file of files) {

                const upload = new Upload({
                    client: this._endpointStore.client,
                    params: {
                        Bucket: this._store.currentBucket,
                        Key: (this._store.currentPath ? this._store.currentPath + '/' : '') + file.name,
                        Body: file,
                        ContentType: file.type
                    }
                })

                const loader = new Loader.ClientLoader()

                upload.on('httpUploadProgress', (progress: number) => {
                    loader.setProgress(progress)
                })

                const promise = upload.done().then(() => {
                    this._store.refresh()
                })

                this._abortControllers.push(upload.abortController)

                loader.setPromise(promise)

                this.uploadings.push({
                    loader: loader,
                    bucket: this._store.currentBucket,
                    path: this._store.currentPath,
                    name: file.name
                })
            }
        }
    }

    toggleUploader () {
        this.uploaderIsOpen = !this.uploaderIsOpen
    }

    cancel (uploading: Uploading) {
        const index = this.uploadings.indexOf(uploading)

        if (index < 0) {
            return
        }

        const abortController = this._abortControllers[index]

        if (!abortController) {
            return
        }

        abortController.abort()
    }

    remove (uploading: Uploading) {
        const index = this.uploadings.indexOf(uploading)

        if (index < 0) {
            return
        }

        const abortController = this._abortControllers[index]

        if (!abortController || uploading.loader.status === 'pending') {
            return
        }

        this.uploadings.splice(index, 1)
        this._abortControllers.splice(index, 1)
    }

    get status (): 'pending' | 'error' | 'done' {
        let almostOnePending = false
        for (const uploading of this.uploadings) {
            if (uploading.loader.status === 'error') {
                return 'error'
            }
            if (uploading.loader.status === 'pending') {
                almostOnePending = true
            }
        }

        return almostOnePending ? 'pending' : 'done'
    }
}

export interface Uploading {
    loader: Loader.ClientLoader
    bucket: string
    path: string
    name: string
}
