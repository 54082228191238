import { makeObservable, observable, action, computed, when, runInAction } from 'mobx'
import { Denormalizable, Normalizable } from '@code-202/serializer'
import Store from '../store'
import { Store as EndpointStore, Loader } from '../../endpoint'
import { S3File, S3FileAdvanced, S3FileType, S3ObjectPublicPermission } from '../entities'
import { filesize } from 'filesize'
import { saveAs } from 'file-saver'
import {
    GetObjectAclCommand, GetObjectAclCommandOutput,
    GetObjectCommand, GetObjectCommandOutput
} from '@aws-sdk/client-s3'
import { getSignedUrl } from '@aws-sdk/s3-request-presigner'
import { ClientLoaderNormalized } from '../../endpoint/loader'

export default class ObjectInspector implements Normalizable<ObjectInspectorNormalized>, Denormalizable<ObjectInspectorNormalized> {
    protected _store: Store
    protected _endpointStore: EndpointStore.Store

    protected _getObjectLoader: Loader.ClientLoader

    public informations: S3FileAdvanced | null = null
    public autoLoadContent: boolean = false
    public enablePreview: boolean = false
    public privateUrl: string  | null = null

    constructor (store: Store, endpointStore: EndpointStore.Store) {
        makeObservable(this, {
            informations: observable,
            autoLoadContent: observable,
            enablePreview: observable,
            privateUrl: observable,

            resetInformations: action,
            enableLoadContent: action,
        })

        this._store = store
        this._endpointStore = endpointStore

        this._getObjectLoader = new Loader.ClientLoader()

        when (
            () => this._endpointStore.ready,
            () => this.getObject()
        )
    }

    get getObjectLoader (): Loader.ClientLoader {
        return this._getObjectLoader
    }

    async getObject () {
        if (!this._store.currentBucket || this._store.currentFile === undefined || !this._endpointStore.client) {
            return
        }

        this.resetInformations()

        let publicPermission: S3ObjectPublicPermission = 'PRIVATE'

        const commandAcl = new GetObjectAclCommand({
            Bucket: this._store.currentBucket,
            Key: this._store.currentFile.key
        })

        const promiseAcl = this._endpointStore.client.send(commandAcl).then(action((data: GetObjectAclCommandOutput) => {
            if (data.Grants) {
                for (const grant of data.Grants) {
                    if (grant.Grantee && grant.Grantee.Type === 'Group' && grant.Grantee.URI === 'http://acs.amazonaws.com/groups/global/AllUsers' && grant.Permission) {
                        publicPermission = grant.Permission as S3ObjectPublicPermission
                    }
                }
            }
        }))

        this._getObjectLoader.setPromise(promiseAcl)

        await promiseAcl

        const command = new GetObjectCommand({
            Bucket: this._store.currentBucket,
            Key: this._store.currentFile.key
        })

        const promise = this._endpointStore.client.send(command).then(action((data: GetObjectCommandOutput) => {
            if (!this._store.currentFile) {
                this.informations = null
                this.autoLoadContent = false
            } else {
                this.informations = {
                    name: this._store.currentFile.name,
                    path: this._store.currentFile.path,
                    key: this._store.currentFile.key,
                    etag: this._store.currentFile.etag,
                    contentType: data.ContentType ? data.ContentType : '',
                    size: this._store.currentFile.size,
                    lastModified: data.LastModified ? data.LastModified : new Date(),
                    publicPermission: publicPermission,
                    type: this.determineFileType(data.ContentType ? data.ContentType : '')
                }

                this.enablePreview = ['pdf', 'img'].indexOf(this.informations.type) >= 0
                this.autoLoadContent = this.enablePreview && parseInt(filesize(this.informations.size, {output: 'exponent'}), 10) < 2
            }
        })).catch(action((error) => {
            this.informations = null
            this.autoLoadContent = false
        }))

        this._getObjectLoader.setPromise(promise)

        await promise

        getSignedUrl(
            this._endpointStore.client,
            new GetObjectCommand({
                Bucket: this._store.currentBucket,
                Key: this._store.currentFile.key,
            }),
            { expiresIn: 15 * 60 }
        ).then(action((url: string) => {
            this.privateUrl = url
        }))
    }

    resetInformations () {
        this.informations = null
        this.privateUrl = null
        this.autoLoadContent = false
        this.enablePreview = false
    }

    get publicUrl (): string {
        return `${this._endpointStore.endpoint}/${this._store.currentBucket}${this._store.currentFile ? '/' + this._store.currentFile.key : ''}`
    }

    enableLoadContent () {
        this.autoLoadContent = true
    }

    download () {
        if (!this.privateUrl) {
            return
        }

        saveAs(this.privateUrl, this._store.currentFile ? this._store.currentFile.name : undefined)
    }

    public determineFileType (contentType: string): S3FileType {
        const reg = new RegExp('^([^/]+)/(.+)$')
        const macthes = contentType.match(reg)

        if (macthes) {
            switch (macthes[1]) {
            case 'application':
                if (macthes[2] === 'pdf') {
                    return 'pdf'
                }
                return 'file'
            case 'image':
                return 'img'
            default:
                return 'file'
            }
        }

        return 'file'
    }

    public normalize(): ObjectInspectorNormalized {
        return {
            informations: this.informations,
            autoLoadContent: this.autoLoadContent,
            enablePreview: this.enablePreview,
            privateUrl: this.privateUrl,
            getObjectLoader: this._getObjectLoader.normalize(),
        }
    }

    public denormalize(data: ObjectInspectorNormalized): void {
        try {
            action(() => {
                this.informations = data.informations
                this.autoLoadContent = data.autoLoadContent
                this.enablePreview = data.enablePreview
                this.privateUrl = data.privateUrl
                this._getObjectLoader.denormalize(data.getObjectLoader)
            })()
        } catch (e) {
            console.error('Impossible to deserialize : bad data')
        }
    }
}

export interface ObjectInspectorNormalized {
    informations: S3FileAdvanced | null
    autoLoadContent: boolean
    enablePreview: boolean
    privateUrl: string  | null
    getObjectLoader: ClientLoaderNormalized
}